import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { endpoints } from "../../config/config";
import axiosInstance from "../../api/axiosInstance";

export const fetchBroadcastList = createAsyncThunk(
  "broadcast/fetch",
  async ({ user_id }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(endpoints.broadcast_list, {
        user_id,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchBroadcastResponse = createAsyncThunk(
  "broadcast/fetchBroadcastResponse",
  async ({ broadcast_id }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(endpoints.broadcast_response, {
        broadcast_id,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const broadcastListSlice = createSlice({
  name: "broadcast",
  initialState: {
    loading: false,
    error: null,
    data: null,
    broadcastDetail: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBroadcastList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBroadcastList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchBroadcastList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchBroadcastResponse.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBroadcastResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.broadcastDetail = action.payload;
      })
      .addCase(fetchBroadcastResponse.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default broadcastListSlice.reducer;
