import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBTDrjfJiA3mZEIOmqUGZL943BVYsxl_cA",
  authDomain: "valiant-monitor-410711.firebaseapp.com",
  projectId: "valiant-monitor-410711",
  storageBucket: "valiant-monitor-410711.appspot.com",
  messagingSenderId: "659121972262",
  appId: "1:659121972262:web:15ce4685446689a41cf2e7",
  measurementId: "G-LNBJ1LPHYJ",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { db, auth, collection, addDoc };
