import { Typography } from '@mui/material'
import React from 'react'

const TermsConditions = () => {
  window.scrollTo(0, 0);
  return (
    <div className='costom-card' style={{padding:"20px", height:"80vh"}}>
      <Typography variant="h4" textAlign={"center"}>Terms & Conditions</Typography>
    </div>
  )
}

export default TermsConditions
