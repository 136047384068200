import React, { useEffect, useState } from "react";
import { StyledBox, StyledOrderBox } from "../../components/UI/StyleBox";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { BackButton } from "../../components/UI/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  addFavoriteVendor,
  fetchFavoriteVendors,
} from "../../features/Vendors/favoriteVendorsSlice";
import { getUser } from "../../utils/authUtils";
import FavoriteIcon from "@mui/icons-material/Favorite";
import StarIcon from "@mui/icons-material/Star";
import Loader from "../../components/comman/Loader";
import defaultImage from "../../assets/images/user.png";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { VenderDetailsAPI } from "../../api/VenderDetails/VenderDetailsAPI";

const VendorCard = ({
  name,
  rating,
  imageUrl,
  provider_id,
  user_id,
  handleAddFavourite,
  is_favourite,
}) => {
  return (
    <NavLink
      to={{
        pathname: "/vender-details",
      }}
      state={{ provider_id: provider_id, user_id: user_id ,DisabledQuantity : true}}
    >
      <Card sx={{ maxWidth: 200, position: "relative" }}>
        <CardMedia
          component="img"
          sx={{
            height: 160,
            width: 175,
            objectFit: "cover",
            margin: "auto",
            borderRadius: "5%",
            marginTop: 2,
          }}
          image={imageUrl || defaultImage}
          alt={name}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="body2"
            sx={{
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: 600,
              lineHeight: "18px",
              textAlign: "left",
            }}
          >
            {name}
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <StarIcon style={{ color: "#FFD700" }} />
            <Typography variant="body2" color="textSecondary" component="p">
              {rating}
            </Typography>
          </div>
        </CardContent>
        <IconButton
          type="button"
          sx={{
            position: "absolute",
            top: 8,
            right: 9,
            color: is_favourite ? "red" : "",
          }}
          aria-label="add to favorites"
          onClick={(e) => handleAddFavourite(e, provider_id)}
        >
          <FavoriteIcon />
        </IconButton>
      </Card>
    </NavLink>
  );
};

const FavouriteVendors = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const data1 = location.state || {};

  const dispatch = useDispatch();
  const { user_id } = getUser();
  const { data, loading, error } = useSelector(
    (state) => state.favoriteVendors
  );
  const [data2, setData2] = useState([]);
  const [showData, setShowData] = useState("");

  const FetchVenderData = async () => {
    setIsLoading(true);
    try {
      const response = await VenderDetailsAPI.vendorsList(data1);
      const ResponseData = response.data;
      if (ResponseData.status === 200) {
        setData2(ResponseData.vendors_list);
      }
    } catch (error) {
      console.error(error);
    }finally{
      setIsLoading(false);
    }
  };

  function getFavouriteVendorList() {
    if (data1.sub_cat_id) {
      FetchVenderData();
      setShowData("data2");
    } else {
      dispatch(fetchFavoriteVendors(user_id));
      setShowData("data");
    }
  }

  useEffect(() => {
    getFavouriteVendorList();
  }, [data1.sub_cat_id, dispatch, user_id]);


  // console.log(data.favourite_vendor_list, "favourite_vendor_list");
  const handleAddFavourite = async (e, provider_id) => {
    e.preventDefault();
    await dispatch(addFavoriteVendor({ user_id, provider_id }));
    await getFavouriteVendorList();
  };
  if (isLoading) return <Loader />;
  if (error) return <p>Error loading data: {error.message}</p>;
  return (
    <>
      <StyledBox>
        <Box>
          <Typography variant="h5" sx={{ color: "#FFFFFF", fontWeight: 600 }}>
            {showData === "data" ? "Favourite Vendors" : "Vendors List"}
          </Typography>
        </Box>
        <Box>
          <BackButton />
        </Box>
      </StyledBox>
      <StyledOrderBox>
        <Container>
          <Grid container spacing={3}>
            {showData === "data" &&
            data &&
            data.favourite_vendor_list &&
            data.favourite_vendor_list.length > 0 ? (
              data.favourite_vendor_list.map((vendor) => (
                <Grid item xs={6} sm={4} md={3} key={vendor.provider_id}>
                  <VendorCard
                    name={vendor.provider_name}
                    rating={vendor.provider_rating}
                    imageUrl={vendor.provider_image}
                    provider_id={vendor.provider_id}
                    user_id={user_id}
                    handleAddFavourite={handleAddFavourite}
                    is_favourite={true}
                  />
                </Grid>
              ))
            ) : showData === "data2" && data2 && data2.length > 0 ? (
              data2.map((vendor) => (
                <Grid item xs={6} sm={4} md={3} key={vendor.provider_id}>
                  <VendorCard
                    name={vendor.name}
                    rating={vendor.ratings}
                    imageUrl={vendor.profile_img}
                    provider_id={vendor.user_id}
                    user_id={user_id}
                    handleAddFavourite={handleAddFavourite}
                    is_favourite={vendor.is_favourite}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  marginTop: 4,
                  padding: 2,
                  color: "gray",
                }}
              >
                Data Not Found !
              </Typography>{" "}
            </Grid>
            )}
          </Grid>
        </Container>
      </StyledOrderBox>
    </>
  );
};

export default FavouriteVendors;
