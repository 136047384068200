import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { endpoints } from "../../config/config";
import axiosInstance from "../../api/axiosInstance";

export const fetchCustomerHomePage = createAsyncThunk( 
  "customer/fetchCustomerHomePage",
  async (user_id, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        endpoints.customer_home_page, {
        user_id: user_id,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data); 
    }
  }
);

const customerSlice = createSlice({
  name: "customer",
  initialState: {
    data: null,
    loading: false,
    error: null,
    servicesList: [],
    topvendorsList: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerHomePage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerHomePage.fulfilled, (state, action) => {
        const { services_list, top_vendors_list } = action.payload;
        state.loading = false;
        state.data = action.payload;
        state.servicesList = services_list;
        state.topvendorsList = top_vendors_list;
      })
      .addCase(fetchCustomerHomePage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default customerSlice.reducer;
