import axios from "axios";
import { BASE_URL } from "../../config/config";
const token = localStorage.getItem("token");


const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};

export const CatagoryListAPI = {
  getCatagoryList: async (data) => {
    var axiosConfig = createAxiosConfig("/category_list", "POST", data);
    return await axios(axiosConfig);
  },

  postBroadcastJob: async (data) => {
    var axiosConfig = createAxiosConfig("/post_broadcast_job", "POST", data);
    return await axios(axiosConfig);
  },
  updateBroadcastJob: async (data) => {
    var axiosConfig = createAxiosConfig("/update_broadcast_job", "POST", data);
    return await axios(axiosConfig);
  },
  changeJobStatus: async (data) => {
    var axiosConfig = createAxiosConfig("/change_job_status", "POST", data);
    return await axios(axiosConfig);
  },

};
