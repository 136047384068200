import React from 'react'
import { Typography } from '@mui/material'
const PrivacyPolicy = () => {
    // write a code for scroll on top
    window.scrollTo(0, 0);
  return (
    <div>
        <div className='costom-card' style={{padding:"20px", height:"80vh"}}>
      <Typography variant="h4" textAlign={"center"}>Privacy Policy</Typography>
    </div>
    </div>
  )
}

export default PrivacyPolicy
