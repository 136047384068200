import axios from "axios";
import { BASE_URL } from "../../config/config";
const token = localStorage.getItem("token");

const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};

export const VenderDetailsAPI = {
  vendorsList: async (data) => {
    var axiosConfig = createAxiosConfig("/vendors_list", "POST", data);
    return await axios(axiosConfig);
  },
  VendorDetails: async (data) => {
    var axiosConfig = createAxiosConfig("/vendor_details", "POST", data);
    return await axios(axiosConfig);
  },
};

export const MakePaymentAPI = {
  makePayment: async (data) => {
    var axiosConfig = createAxiosConfig("/make_payment", "POST", data);
    return await axios(axiosConfig);
  },
  applyCoupon: async (data) => {
    var axiosConfig = createAxiosConfig("/apply_coupon", "POST", data);
    return await axios(axiosConfig);
  },
  postJob: async (data) => {
    var axiosConfig = createAxiosConfig("/post_job", "POST", data);
    return await axios(axiosConfig);
  },
};
