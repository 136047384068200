import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";

export const BackButton = ({ color = "#FFFFFF", ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBack = () => {
    const currentLocation = location.pathname;
    const previousPath = document.referrer;
    navigate(-1);
  };

  return (
    <IconButton onClick={handleBack} {...props}>
      <ArrowBackIcon sx={{ color }} />
    </IconButton>
  );
};

export const CustomBlockButton = ({ label }) => (
  <Button
    variant="contained"
    size="small"
    sx={{
      backgroundColor: "#FFA500",
      borderRadius: "2rem",
      "&:hover": {
        backgroundColor: "#FFD700",
      },
    }}
  >
    {label}
  </Button>
);
