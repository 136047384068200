import React from "react";
import { motion } from "framer-motion";
import loaderGif from "../../assets/images/logo.png";
// import loaderGif from "../../assets/images/logo-animation.gif";

const Loader = () => (
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      zIndex: 9999,
    }}
  >
    {/* <img src={loaderGif} alt="Loading......." width={500} /> */}
    <motion.img
      src={loaderGif}
      alt="Loading......."
      width={300}
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        repeat: Infinity,
        repeatType: "reverse",
        ease: "easeInOut",
        scale: {
          type: "keyframes",
          values: [1, 1.1, 1],
          times: [0, 0.5, 1],
        },
      }}
    />
  </div>
);

export default Loader;
