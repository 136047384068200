import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";
import { endpoints } from "../../config/config";

export const fetchFavoriteVendors = createAsyncThunk(
  "favoriteVendors/fetchFavoriteVendors",
  async (userId, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        endpoints.favourite_vendor_list,
        { user_id: userId }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.message);
    }
  }
);

export const addFavoriteVendor = createAsyncThunk(
  "favoriteVendors/addFavoriteVendor",
  async ({ user_id, provider_id }, { rejectWithValue }) => {
    console.log( user_id, provider_id )
    try {
      const response = await axiosInstance.post(endpoints.favourite_vendor, {
        user_id,
        provider_id,
      });
      console.log(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const favoriteVendorsSlice = createSlice({
  name: "favoriteVendors",
  initialState: {
    data: null,
    loading: false,
    error: null,
    favoriteloading: false,
    favoriteerror: null,
    favoritedata: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFavoriteVendors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFavoriteVendors.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(fetchFavoriteVendors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addFavoriteVendor.pending, (state) => {
        state.favoriteloading = true;
        state.favoriteerror = null;
      })
      .addCase(addFavoriteVendor.fulfilled, (state, action) => {
        state.favoriteloading = false;
        state.favoritedata = action.payload;
      })
      .addCase(addFavoriteVendor.rejected, (state, action) => {
        state.favoriteloading = false;
        state.favoriteerror = action.payload;
      });
  },
});

export default favoriteVendorsSlice.reducer;
