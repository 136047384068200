import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import paypal from "../../assets/images/paypal.png";
import pesapal from "../../assets/images/pesapal.png";
import { MakePaymentAPI } from "../../api/VenderDetails/VenderDetailsAPI";

const PaymentMethod = ({ setPaymentType ,paymentType }) => {
  const [selectedValue, setSelectedValue] = React.useState("pesapal");
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (event) => {
    setPaymentType(event.target.value);
 
  };

  const handlePayment = async () => {
    const data = {
      user_id: 75,
      job_id: 48,
      amount: 10.12,
      payment_method: paymentType.toUpperCase(),
      type: "BROADCAST_JOB",
    };
    setIsLoading(true);
    try {
      const response = await MakePaymentAPI.makePayment(data);
      const responseData = response.data;
      if (responseData.status === 200) {
        window.location.href = responseData.redirect_url;
        // window.open(responseData.redirect_url, "_blank");
      } else {
        console.error("Payment processing error:", response.data.msg);
      }
     
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Typography variant="h6" sx={{ mb: 2, textAlign: "center", width: "100%" }}>

      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // border: "1px solid #ccc",
          width: "100%",
          borderRadius: "8px",
          padding: "8px",
          marginBottom: "8px",
          width: "100%",
    
          mx: "auto",
        }}
      >
        <Grid container alignItems="center" sx={{ mb: 1 }}>
          <Grid item xs={10} sm={11}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={paypal}
                alt="Paspal"
                style={{ width: "24px", height: "24px" }}
              />
              <Typography variant="body2" sx={{ ml: 2 }}>
                paypal
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={2} sm={1}>
            <Radio
              checked={paymentType === "paypal"}
              onChange={handleChange}
              value="paypal"
              name="payment-method"
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" sx={{ mb: 1 }}>
          <Grid item xs={10} sm={11}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={pesapal}
                alt="Paspal"
                style={{ width: "24px", height: "24px" }}
              />
              <Typography variant="body2" sx={{ ml: 2 }}>
                pesapal
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} sm={1}>
            <Radio
              checked={paymentType === "pesapal"}
              onChange={handleChange}
              value="pesapal"
              name="payment-method"
            />
          </Grid>
        </Grid>
       
      </Box>{" "}
    </>
  );
};

export default PaymentMethod;
