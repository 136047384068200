import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Avatar,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import HomeIcon from "@mui/icons-material/Home";
import EditableField from "./EditableField";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateProfile } from "../../features/Profile/profileSlice";
import { notifyError, notifySuccess } from "../../utils/notificationService";
import CustomTextField from "../comman/CustomTextField";
import Loader from "../comman/Loader";
import withLoader from "../comman/withLoader";

const ProfileForm = ({ getUserProfile }) => {
  const dispatch = useDispatch();
  const { userProfile, loading } = useSelector((state) => state.profile);
  const {
    user_id,
    username,
    name,
    description,
    profile_image,
    image_1,
    image_2,
    image_3,
    mobile,
    email,
    address,
    lat,
    long,
  } = userProfile ?? {};
  const [isMobileChangeEnabled, setMobileChangeEnabled] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [fromEditForm, setFromEditForm] = useState("");
  const handleEnableChange = () => {
    setMobileChangeEnabled(true);
  };

  useEffect(() => {
    setImagePreview(profile_image);
    setProfileImage(profile_image);
  }, [profile_image]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_id: user_id,
      username: "",
      profile_image: "",
      image_1: "",
      image_2: "",
      image_3: "",
      name: name ?? "",
      address: address ?? "",
      description: " ",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      address: Yup.string().required("Address is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const formData = new FormData();
        for (const key in values) {
          formData.append(key, values[key]);
        }
        const { payload } = await dispatch(updateProfile(formData));
        if (payload.status === 200) {
          notifySuccess(payload.msg);
          getUserProfile();
        } else {
          notifyError(payload.msg);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setProfileImage(file);
      setImagePreview(URL.createObjectURL(file));
      formik.setFieldValue("profile_image", file);
    } else {
      notifyError("Please select a valid image file.");
      setProfileImage(null);
      setImagePreview(null);
    }
  };

  const { isSubmitting } = formik;
  // if (loading) return <Loader />;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        p: 2,
        border: "1px solid #ddd",
        borderRadius: "8px",
        maxWidth: "900px",
        margin: "auto",
        overflow: "hidden",
      }}
    >
      {" "}
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={4}
            md={5}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                alt="Albert Miller"
                src={imagePreview}
                sx={{ width: 200, height: 200, mb: 2 }}
              />
              <Button variant="contained" component="label" sx={{ mt: 2 }}>
                Upload Profile Image
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={7}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  id="name"
                  label="Name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  endAdornmentIcon={PersonIcon}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12}>
                <EditableField
                  label="Mobile Number"
                  isMobile={true}
                  userValue={mobile}
                  fromEditForm={"mobile number"}
                />
              </Grid>
              <Grid item xs={12}>
                <EditableField
                  label="Email Address"
                  userValue={email}
                  isMobile={false}
                  fromEditForm={"email"}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  name="address"
                  label="Address"
                  type="text"
                  id="address"
                  autoComplete="address"
                  endAdornmentIcon={HomeIcon}
                  formik={formik}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  // fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  startIcon={isSubmitting && <CircularProgress size={24} />}
                >
                  {isSubmitting ? "Update..." : "Update"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default withLoader(ProfileForm);
