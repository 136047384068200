import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: "Poppins, Nunito, sans-serif",
    h1: {
      fontFamily: "Nunito",
      fontSize: "36px",
      fontWeight: 700,
      lineHeight: "49.1px",
      textAlign: "center",
    },
    // h2: {
    //   fontFamily: 'Nunito',
    //   fontSize: '36px',
    //   fontWeight: 700,
    //   lineHeight: '49.1px',
    //   textAlign: 'center',
    // },
    body1: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      textAlign: "center",
    },
    body2: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "23px",
      textAlign: "center",
    },
    h5: {
      fontFamily: "Poppins",
      fontSize: "28px",
      fontWeight: 500,
      lineHeight: "30px",
      textAlign: "center",
    },
    h6: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "30px",
      textAlign: "center",
    },
  },
  palette: {
    primary: {
      main: "#0079EA",
    },
    secondary: {
      main: "#444444",
    },
    divider: "#555555",
  },
  spacing: 8,
});

export default theme;
