import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";

export const Root = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  height: "90vh",
  padding: 0,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const Sidebar = styled(Box)(({ theme }) => ({
  width: "25%",
  padding: theme.spacing(2),
  backgroundColor: "#f5f5f5",
  borderRight: "1px solid #ddd",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    borderRight: "none",
    borderBottom: "1px solid #ddd",
  },
}));

export const ChatArea = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const ChatHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderBottom: "1px solid #ddd",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  display: "flex",
  alignItems: "center",
  justifyContent:"space-between",
  position:"sticky"
}));

export const ChatMessages = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  overflowY: "scroll",
  backgroundColor: "#fff",
  '&::-webkit-scrollbar': {
    width: '0.4em',
    height: '0.4em'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '8px'
  },'&::-webkit-scrollbar-track': {
    boxShadow: `inset 0 0 6px ${theme.palette.grey[300]}`,
    borderRadius: '8px'
  }
}));
export const Message = styled(Typography)(({ theme, align }) => ({
  marginBottom: theme.spacing(2),
  textAlign: align,
}));

export const MessageLeft = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  marginBottom: theme.spacing(1),
}));

export const MessageRight = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: theme.spacing(1),
}));

export const MessageBox = styled(Box)(({ theme, align }) => ({
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: align === "left" ? "#e0e0e0" : theme.palette.primary.main,
  color: align === "left" ? "#000" : "#fff",
  maxWidth: "60%",
}));

export const ChatInput = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  borderTop: "1px solid #ddd",
  backgroundColor: "#f5f5f5",
}));
export const SkillButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
