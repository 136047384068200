import React from 'react'
import { Typography } from '@mui/material'
const AboutUs = () => {
    window.scrollTo(0, 0);
    return (
      <div className='costom-card' style={{padding:"20px", height:"80vh"}}>
        <Typography variant="h4" textAlign={"center"}>About Us</Typography>
      </div>
    )
}

export default AboutUs
