import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance, { setAuthToken } from "../../api/axiosInstance";
import { endpoints } from "../../config/config";
import { clearAuth, getToken, getUser, setToken, setUser } from "../../utils/authUtils";

const initialState = {
  user: getUser(),
  signupUser: null,
  token: getToken(),
  userData: getUser(),
  loading: false,
  error: null,
};

const handleAuthResponse = (data) => {
  if (data.status === 200 && data.payload.api_token) {
    const token = data.payload.api_token.access;
    setToken(token);
    setUser(data.payload);
  }
  return data;
};

export const login = createAsyncThunk(
  "auth/login",
  async ({ loginData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(endpoints.login, loginData);
      return handleAuthResponse(response.data);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (loginData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(endpoints.signup, loginData);
      return handleAuthResponse(response.data);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      state.user = null;
      state.userData = null;
      clearAuth();
      // window.location.href="/login"
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.signupUser = action.payload.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
