import React from "react";
import ResponsiveAppBar from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Container, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import HomeNavLink from "./HomeNavLink";
const styles = {
  main: {
    paddingTop: "4rem",
  },
  container: {
    // padding: "20px",
    // backgroundColor: "#f4f4f4",
    // borderRadius: "8px",
    // boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
};
const DashboardLayout = ({ children }) => {
  return (
    <>
      <ResponsiveAppBar>
        <HomeNavLink />
      </ResponsiveAppBar>
      <main style={styles.main}>{children}</main>
      <Footer />
    </>
  );
};

export default DashboardLayout;
