import React from "react";
import { motion } from "framer-motion";
import { Dialog } from "@mui/material";

const AnimatedDialog = ({ open, onClose, children, ...props }) => {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      PaperProps={{
        component: motion.div,
        initial: { opacity: 0, y: 50 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 50 },
        transition: { duration: 0.5 }, 
        ...props.PaperProps,
        sx: {
          borderRadius: "1rem",
          maxWidth: "500px",
          mx: "auto",
          // p:1,
          ...props.PaperProps?.sx,
        },
      }}
      {...props}
    >
      {children}
    </Dialog>
  );
};

export default AnimatedDialog;
