import React, { useState } from "react";
import {
  Snackbar,
  Alert,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import { formateDate, timeAgo } from "../../utils/dateUtils";
import { useUserAuth } from "../../context/UserAuthContext";

const Notifications = ({ anchorEl, setAnchorEl }) => {
  const { getNotification } = useUserAuth();
  const { notifications, loading, error } = useSelector(
    (state) => state.notification
  );
  const handleClose = (event) => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ width: "25rem" }}>
          <List>
            {notifications?.notification_list?.length > 0 ?  (notifications?.notification_list?.map((note, index) => (
              <ListItem
                key={index}
                alignItems="flex-start"
                style={{ borderBottom: "1px solid #ccc" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 60,
                    height: 60,
                    backgroundColor: "#e0f7fa",
                    borderRadius: "8px",
                    marginRight: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ color: "#0079ea", fontSize: ".9rem" }}
                  >
                    {formateDate(note.created_dt).split(" ")[0]}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "#0079ea", fontSize: ".5rem" }}
                  >
                    {formateDate(note.created_dt).split(" ")[1]}{" "}
                    {formateDate(note.created_dt).split(" ")[2]}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "0.75rem",
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                      textAlign: "left",
                    }}
                  >
                    {note.msg_content}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      fontSize: "0.5rem",
                      textAlign: "right",
                      marginTop: "4px",
                    }}
                  >
                    {timeAgo(note.created_dt)}
                  </Typography>
                </Box>
              </ListItem>
            ))) : ( <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                marginTop: 4,
                padding: 2,
                color: "gray",
              }}
            >
             No Notifications yet.
            </Typography>)}
          </List>
        </div>
      </Popover>
    </>
  );
};

export default Notifications;

// {
//     "msg_content": "Home Services Broadcast Job is cancelled by customer",
//     "is_seen": false,
//     "notification_type": "CANCEL_BROADCAST_JOB",
//     "created_dt": "2024-04-29T12:35:25.432293",
//     "notification_id": 224,
//     "job_id": 42,
//     "service": "Home Services"
// }
