import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { VenderDetailsAPI } from "../../api/VenderDetails/VenderDetailsAPI";
import { FaMapMarkerAlt, FaStar } from "react-icons/fa";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Form,
  ModalHeader,
} from "reactstrap";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { FaRegQuestionCircle } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import PaymentMethod from "../Payment/Payment";
import "react-datepicker/dist/react-datepicker.css";
import "react-clock/dist/Clock.css";
import { MdOutlineCancel } from "react-icons/md";
import defaultImage from "../../assets/images/user.png";
import Loader from "../../components/comman/Loader";
import Demo from "./Demo";
import MapHelper from "../PostBroadCast/MapHelper";
import TimeIcon from "../../assets/images/calendar-clock 1.png";
import Marker from "../../assets/images/marker9.png";
import { getUser } from "../../utils/authUtils";
import { IoChevronForward } from "react-icons/io5";
import insurance from "../../assets/images/insurance1.png";
import Tag from "../../assets/images/Tag.png";
import { MakePaymentAPI } from "../../api/VenderDetails/VenderDetailsAPI";
import { notifyError, notifySuccess } from "../../utils/notificationService";
import { IoArrowBackCircleOutline } from "react-icons/io5";
const VenderDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state || {};
  const storedDate = localStorage.getItem("user");
  const { mobile } = getUser();
  const [mobileNumber, setMobileNumber] = useState(mobile);
  const [coupon, setCoupon] = useState("");

  const [Data, setData] = useState(data);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [venderList, setVenderList] = useState([]);
  const [activeProfile, setActiveProfile] = useState([]);
  const [sub_cat_id, setSub_cat_id] = useState(data);
  const [SubCatagory, setSubCatagory] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState([]);

  const [initialSubCat, setInitialSubCat] = useState([]);
  const [activeInitialSubCat, setActiveInitialSubCat] = useState(null);
  const [avalableServices, setAvalableServices] = useState([]);
  const [avalableCat, setAvalableCat] = useState([]);
  const [avalableCatName, setAvalableCatName] = useState([]);
  const [selectedCatagory, setSelectedCatagory] = useState([]);
  const [open, setOpen] = useState(false);
  const [dateModal, setDateModal] = useState(false);
  const [temp, setTemp] = useState("");
  const [pendingCategory, setPendingCategory] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [cart, setCart] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [showMap, setShowMap] = useState(false);
  const [finalCoordinates, setFinalCoordinates] = useState("");
  const [address, setAddress] = useState("");
  const [isPastTime, setIsPastTime] = useState(false);
  const [firstPart, setFirstPart] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [TotalItems, setTotalItems] = useState({
    total: 0,
    catFees: 0,
    tax: 0,
  });
  const [catFee, setCatFee] = useState(0);
  const [tax, setTax] = useState(0);
  const [Couponmodal, setCouponModal] = useState(false);
  const [discount, setDiscount] = useState("");
  const [paymentType, setPaymentType] = useState("pesapal");
  const [demoFlag, setDemoFlag] = useState(false);
  const [selectedDate1, setSelectedDate1] = useState();
  const [selectedTime1, setSelectedTime1] = useState();
  const [SelectedAddress, setSelectedAddress] = useState();

  const toggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    GetVenderDetails();
  }, []);

  const [quantities, setQuantities] = useState(
    avalableServices.reduce((acc, service) => {
      acc[service.service_id] = 0;
      return acc;
    }, {})
  );

  const handleAddClick = (serviceId) => {
    const service = avalableServices.find((s) => s.service_id === serviceId);
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [serviceId]: 1,
    }));
    setCart((prevCart) => [...prevCart, { ...service, quantity: 1 }]);
  };

  const incrementQuantity = (serviceId) => {
    const service = avalableServices.find((s) => s.service_id === serviceId);
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [serviceId]: (prevQuantities[serviceId] || 0) + 1,
    }));
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.service_id === serviceId
          ? { ...item, quantity: item.quantity + 1 }
          : item
      )
    );
  };

  const decrementQuantity = (serviceId) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [serviceId]: Math.max((prevQuantities[serviceId] || 0) - 1, 0),
    }));
    setCart((prevCart) =>
      prevCart
        .map((item) =>
          item.service_id === serviceId
            ? { ...item, quantity: Math.max(item.quantity - 1, 0) }
            : item
        )
        .filter((item) => item.quantity > 0)
    );
  };
  const handleSelectedCat = async (item) => {
    setSelectedItemId("");

    setSelectedItemId(item.user_id);
    setActiveProfile(item);
    let SubCat = item?.services_list.find(
      (sub) => sub?.sub_cat_id === sub_cat_id
    );

    setSubCatagory(SubCat.sub_cat_name);

    GetVenderDetails(item.user_id);
  };

  const GetVenderDetails = async () => {
    setIsLoading(true);
    let data = {
      user_id: Data.user_id,
      vendor_id: Data.provider_id,
    };

    try {
      const response = await VenderDetailsAPI.VendorDetails(data);
      const ResponseData = response.data;
      if (ResponseData.status === 200) {
        setSelectedProfile(ResponseData);
        setSelectedCatagory(ResponseData.categories_list);
        setInitialSubCat(ResponseData.categories_list[0].sub_cat_list);
        setActiveInitialSubCat(
          ResponseData.categories_list[0].sub_cat_list[0].sub_cat_id
        );
        setActiveCategory(ResponseData.categories_list[0].cat_id);
        setTax(ResponseData.categories_list[0].cat_tax);
        setCatFee(ResponseData.categories_list[0].catFees);
        setAvalableServices(
          ResponseData.categories_list[0].sub_cat_list[0].available_service
        );
        setAvalableCat(
          ResponseData.categories_list[0].sub_cat_list[0].available_service[0]
        );
        setAvalableCatName(ResponseData.categories_list[0]);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleCatagoryClick = (item) => {
    setCatFee(item.catFees);
    setTax(item.cat_tax);
    setActiveInitialSubCat(item.sub_cat_list[0].sub_cat_id);
    const hasQuantities = Object.values(quantities).some(
      (quantity) => quantity > 0
    );
    if (!hasQuantities || activeCategory === item.cat_id) {
      setInitialSubCat(item.sub_cat_list);
      setActiveCategory(item.cat_id);
      setAvalableServices(item.sub_cat_list[0].available_service);
    } else {
      setPendingCategory(item);
      setOpen(true);
    }
  };

  const handleServiceClick = (item) => {
    setActiveInitialSubCat(item.sub_cat_id);
    setAvalableServices(item.available_service);
  };

  const handleClearAndProceed = () => {
    setQuantities({});
    setCart([]);
    if (pendingCategory) {
      setInitialSubCat(pendingCategory.sub_cat_list);
      setAvalableServices(pendingCategory.sub_cat_list[0].available_service);
      setActiveCategory(pendingCategory.cat_id);
    }
    setOpen(false);
    setPendingCategory(null);
  };
  const handleDeleteClick = (serviceId) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [serviceId]: 0,
    }));
    setCart((prevCart) =>
      prevCart.filter((item) => item.service_id !== serviceId)
    );
  };
  const calculateTotals = () => {
    const totals = cart.reduce(
      (acc, item) => {
        const itemTotal = item.amount * item.quantity;
        const itemFees = item.catFees * item.quantity; // Assuming catFees applies per item

        acc.total += itemTotal;
        acc.catFees += itemFees;

        return acc;
      },
      { total: 0, catFees: 0 }
    );
    return totals;
  };
  useEffect(() => {
    const totals = calculateTotals();
    setTotalItems(totals);
  }, [cart]);

  const [selectedDate, setSelectedDate] = useState();

  const [selectedTime, setSelectedTime] = useState("10:00");

  const [availableDates, setAvailableDates] = useState([]);

  useEffect(() => {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    const isAM = hours < 12;

    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    // Ensure hours and minutes are always two digits with leading zeros if necessary
    const formattedHours = hours < 10 ? "0" + hours : hours;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedTime = `${formattedHours}:${formattedMinutes} ${
      isAM ? "AM" : "PM"
    }`;

    setSelectedTime(formattedTime); // Assuming setIsOpen is setting state correctly
  }, []);
  useEffect(() => {
    const today = new Date();
    const dates = [];
    for (let i = 0; i < 6; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      dates.push(date);
    }
    setAvailableDates(dates);
    setSelectedDate(dates[0]);
  }, []);

  const handelMap = () => {
    setShowMap(true);
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handelEdit = () => {
    setDateModal(false);
  };

  const EditMap = () => {
    setDateModal(true);
    setShowMap(true);
    setDemoFlag(true);
  };

  const calculateTax = () => {
    const totalAmount = TotalItems.total;
    const discountedTotal = catFee + totalAmount - CouponDiscount(); // Calculate discounted total
    const taxAmount = (discountedTotal * tax) / 100; // Calculate tax on discounted amount
    return taxAmount.toFixed(2);
  };

  const CouponDiscount = () => {
    const totalAmount = TotalItems.total;

    if (!discount) return 0;
    const discountAmount = (totalAmount * discount.coupon_discount) / 100;

    if (discountAmount > discount.coupon_max_discount) {
      return discount.coupon_max_discount;
    }
    return discountAmount;
  };

  const calculateTotal = () => {
    const totalAmount = catFee + TotalItems.total;
    const taxAmount = calculateTax();
    const discount = CouponDiscount();

    let finalAmount =
      Number(totalAmount) + Number(taxAmount) - Number(discount);

    return finalAmount.toFixed(2);
  };

  const handleInputChange = async (e) => {
    setCoupon(e.target.value);
  };

  const handleApplyCoupon = async () => {
    let data = {
      user_id: Data.user_id,
      provider_id: Data.provider_id,
      coupon_code: coupon,
    };
    try {
      const response = await MakePaymentAPI.applyCoupon(data);

      const responseData = response.data;
      if (responseData.status === 200) {
        setCouponModal(false);
        setCouponCode(coupon);
        setDiscount(responseData);
      } else {
        setCouponModal(false);
        notifyError(responseData.msg);
        setCoupon("");
      }
    } catch (err) {}
  };

  const HandelRemove = () => {
    setCoupon("");
    setCouponCode("");
    setDiscount("");
  };
  const formatDateTime = (date, time) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");

    let [timeString, period] = time.split(" ");
    let [hours, minutes] = timeString.split(":");

    hours = parseInt(hours, 10);
    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    const formattedHours = String(hours).padStart(2, "0");
    const formattedTime = `${year}-${month}-${day}T${formattedHours}:${minutes}`;

    return formattedTime;
  };

  // console.log(selectedDate1, selectedTime1 ,"selectedDate1, selectedTime1")
  // console.error(formatDateTime(selectedDate1, selectedTime1) ,"formatDateTime(selectedDate1, selectedTime1)")

  const PostJob = async () => {
    const service_list = cart.map((service) => ({
      service_id: service.service_id,
      qty: service.quantity,
    }));

    let data = {
      user_id: Data.user_id,
      provider_id: Data.provider_id,
      brodcast_id: "",
      service_list: service_list,
      service_charge: TotalItems.total,
      coupon_discount: CouponDiscount(),
      tax: tax,
      tax_amt: calculateTax(),
      fees: catFee,
      total_amt: calculateTotal(),
      payment_method: paymentType,
      date_time: formatDateTime(selectedDate1, selectedTime1),
      job_location: SelectedAddress,
      lat: finalCoordinates.lat,
      lng: finalCoordinates.lng,
      coupon_code: discount.coupon_discount,
    };
    try {
      setIsLoading(true);
      const response = await MakePaymentAPI.postJob(data);
      const responseData = response.data;
      if (responseData.status === 200) {
        // notifySuccess(responseData.msg);
        window.location.href = responseData?.payment_data?.redirect_url;
      } else {
        notifyError(responseData.msg);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const handelDateModal = (e) => {
    e.preventDefault();
    setDateModal(true);
    setShowMap(false);
  };

  const HandelDateAndMap = (e) => {
    setSelectedDate1(selectedDate);
    setSelectedTime1(selectedTime);
    e.preventDefault();
    setShowMap(true);
    setDemoFlag(true);
  };

  const HandelDateAndMapOnlyDate = (e) => {
    e.preventDefault();
    setSelectedTime1(selectedTime);
    setSelectedDate1(selectedDate);
    setDateModal(false);
    setShowMap(false);
    setDemoFlag(false);
  };

  const handelPayment = () => {
    setIsPayment(true);
  };

  const handelBack = () => {
    if (firstPart === true) {
      navigate(-1);
    } else {
      setFirstPart(true);
      setIsEdit(true);
    }
  };

  if (isLoading) return <Loader />;
  return (
    <>
      <div className="col-lg-12">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ backgroundColor: "#0079EA", height: "65px" }}
        >
          <p
            className="ms-md-5"
            style={{
              color: "#FFFFFF",
              fontWeight: "600",
              fontSize: "20px",
            }}
          >
            Vender Details
          </p>
          <IoArrowBackCircleOutline
            onClick={handelBack}
            style={{ color: "#FFFFFF" }}
            size={35}
            className="me-md-5"
          />
        </div>
      </div>
      {firstPart === true && (
        <>
          {selectedProfile.vendor_id ? (
            <div className="p-2 mx-4 mb-1">
              <div className="row">
                <div className="col-12 " style={{ backgroundColor: "#F1F1F1" }}>
                  <div className="container m-3">
                    <div className="d-flex align-items-center justify-content-start">
                      <img
                        src={selectedProfile?.profile_img || defaultImage}
                        alt={selectedProfile?.name}
                        className="img-fluid rounded-circle"
                        style={{
                          width: "80px",
                          height: "80px",
                        }}
                      />
                      <div className="ms-3">
                        <h5 className="mb-1 CostomCard-title">
                          {selectedProfile?.name}
                        </h5>
                        <div className="d-flex align-items-center  mb-1">
                          <span
                            className="me-2"
                            style={{
                              color: "#0079EA",
                              fontWeight: "600",
                              fontSize: "14px",
                            }}
                          >
                            {SubCatagory}
                          </span>
                          <FaStar className="text-warning me-1" />
                          <span>{selectedProfile?.ratings}</span>
                        </div>
                        <div className="d-flex align-items-center text-muted">
                          <FaMapMarkerAlt className="me-1" />
                          <span>{selectedProfile?.location}</span>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
                {selectedCatagory && selectedCatagory?.length > 0 ? (
                  <></>
                ) : (
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: "center",
                        marginTop: 4,
                        padding: 2,
                        color: "gray",
                        height: "50vh",
                      }}
                    >
                      Data Not Found !
                    </Typography>{" "}
                  </Grid>
                )}

                <div className="col-md-4">
                  <div className="row">
                    {selectedCatagory && selectedCatagory?.length > 0
                      ? selectedCatagory?.map((service) => (
                          <>
                            <div
                              key={service.cat_id}
                              className={` align-items-center ${
                                activeCategory === service.cat_id
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => handleCatagoryClick(service)}
                            >
                              <div
                                className="service-box"
                                style={
                                  activeCategory === service.cat_id
                                    ? {
                                        cursor: "pointer",
                                        border: "2px solid #0079EA",

                                        backgroundColor: "#F1F8FF",
                                      }
                                    : {}
                                }
                              >
                                <p className=" text-center">
                                  {service.cat_name}
                                </p>
                                <img
                                  src={service.cat_img}
                                  alt={service.cat_name}
                                  width="60px"
                                  height="60px"
                                  padding="5px"
                                  style={{ margin: "10px" }}
                                />
                              </div>
                            </div>
                          </>
                        ))
                      : null}
                  </div>
                </div>

                {initialSubCat && initialSubCat.length > 0 ? (
                  <div className="col-md-8">
                    <div className="CostomCard p-3">
                      <div className="row">
                        <h2
                          style={{
                            fontWeight: "600",
                            fontSize: "18px",
                            color: "#868686",
                            lineHeight: "24.55px",
                            fontFamily: "Nunito",
                          }}
                        >
                          Choose service categorys
                        </h2>

                        {initialSubCat && initialSubCat.length > 0
                          ? initialSubCat.map((service) => (
                              <div
                                key={service.sub_cat_id}
                                className="col-auto normalBox d-flex align-items-center"
                                onClick={() => handleServiceClick(service)}
                                style={
                                  activeInitialSubCat === service.sub_cat_id
                                    ? {
                                        cursor: "pointer",
                                        border: "1px solid #0079EA",

                                        backgroundColor: "#F1F8FF",
                                      }
                                    : {
                                        cursor: "pointer",
                                      }
                                }
                              >
                                <img
                                  src={service.sub_cat_img}
                                  alt={service.sub_cat_name}
                                  width="20"
                                  height="20"
                                  className="me-1"
                                />
                                <p
                                  className="mb-0"
                                  style={{ fontSize: "13px" }}
                                >
                                  {service.sub_cat_name}
                                </p>
                              </div>
                            ))
                          : null}
                      </div>
                      <div className="row">
                        {avalableServices.length > 0 ? (
                          <h6
                            className=""
                            style={{
                              color: "#0079EA",
                              fontWeight: "600",
                              fontSize: "14px",
                            }}
                          >{`Found ${avalableServices.length} services for ${avalableCatName.cat_name} `}</h6>
                        ) : null}
                      </div>

                      <div className="row ">
                        <div className="container ">
                          {avalableCat && avalableServices.length > 0
                            ? avalableServices.map((service) => (
                                <div
                                  key={service.service_id}
                                  className="row mb-2 "
                                  style={{ justifyContent: "end" }}
                                >
                                  <div className="col-8">
                                    <p
                                      style={{
                                        fontWeight: "600",
                                        color: "#444444",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {service.title}
                                    </p>
                                    {service.include_points.map(
                                      (point, index) => (
                                        <ul key={index}>
                                          <li
                                            style={{
                                              color: "#868686",
                                              fontSize: "13px",
                                              lineHeight: "9.5px",
                                            }}
                                          >
                                            {point}
                                          </li>
                                        </ul>
                                      )
                                    )}
                                  </div>
                                  <div className="col-3">
                                    <div className="float-end">
                                      <img
                                        src={service.image}
                                        alt="service Image"
                                        width="100"
                                        height="100"
                                        style={{ borderRadius: "5px" }}
                                      />
                                      {data.DisabledQuantity && (
                                        <>
                                          {quantities[service.service_id] >
                                          0 ? (
                                            <div
                                              style={{
                                                border: "1px solid #007bff",
                                                borderRadius: "5px",
                                                overflow: "hidden",
                                                marginTop: "10px",
                                                paddingRight: "10px",
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              <div
                                                className="quantity-control"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <button
                                                  className="decrement"
                                                  onClick={() =>
                                                    decrementQuantity(
                                                      service.service_id
                                                    )
                                                  }
                                                  style={{
                                                    borderRadius: "0",
                                                    margin: "0",
                                                  }}
                                                >
                                                  -
                                                </button>
                                                <span
                                                  id="quantity"
                                                  style={{ margin: "0 10px" }}
                                                >
                                                  {quantities[
                                                    service.service_id
                                                  ] || 0}
                                                </span>
                                                <button
                                                  className="increment"
                                                  onClick={() =>
                                                    incrementQuantity(
                                                      service.service_id
                                                    )
                                                  }
                                                  style={{
                                                    borderRadius: "0",
                                                    margin: "0",
                                                  }}
                                                >
                                                  +
                                                </button>
                                              </div>
                                            </div>
                                          ) : (
                                            <button
                                              className="add-button"
                                              onClick={() =>
                                                handleAddClick(
                                                  service.service_id
                                                )
                                              }
                                            >
                                              Add
                                            </button>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="cart">
                      {cart && cart.length > 0 && (
                        <>
                          <h3
                            style={{
                              fontWeight: "700",
                              fontSize: "22px",
                              lineHeight: "30px",
                            }}
                          >
                            Cart
                          </h3>
                          <div className="CostomCard">
                            <div>
                              {cart.map((item) => (
                                <div
                                  key={item.service_id}
                                  className="cart-item"
                                >
                                  <div className="row">
                                    <p className="col-7">{item.title}</p>
                                    <p className="col-2">
                                      Qty. {item.quantity}
                                    </p>
                                    <div className="d-flex justify-content-end col-3">
                                      <p className="mx-1">KES {item.amount}</p>

                                      <FaTrashAlt
                                        onClick={() =>
                                          handleDeleteClick(item.service_id)
                                        }
                                        className="mx-1"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "20px",
                                          color: "red",
                                        }}
                                        tooltip="Delete"
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <hr className="DottedLine"></hr>
                              <div className=" d-flex justify-content-between">
                                <p
                                  style={{
                                    fontWeight: "700",
                                    fontSize: "22px",
                                    lineHeight: "33px",
                                    color: "#1E1E1E",
                                  }}
                                >
                                  KES {TotalItems.total}
                                </p>

                                <button
                                  className={"button1"}
                                  onClick={() =>
                                    isEdit
                                      ? setFirstPart(false)
                                      : setDateModal(true)
                                  }
                                >
                                  <span>Proceed</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </>
      )}
      {firstPart === false && (
        <>
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <h4
                  style={{
                    color: "#1E1E1E",
                    fontWeight: "700",
                    fontFamily: "Nunito",
                    fontSize: "18px",
                    lineHeight: "24.5px",
                  }}
                >
                  Date & Time
                </h4>
                <div className="CostomCard">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          backgroundColor: "#E8F3FD",
                          borderRadius: "5px",
                          width: "34px",
                          height: "34px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "10px",
                        }}
                      >
                        <img src={TimeIcon} alt="Time Icon" />
                      </div>
                      <p style={{ margin: 0 }}>
                        {formatDate(selectedDate1)}
                        {","} {selectedTime1}
                      </p>
                    </div>
                    <button
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "#F1F1F1",
                        color: "#1E1E1E",
                        borderRadius: "8px",
                        height: "35px",
                        width: "80px",
                        padding: "8px 8px",
                        fontSize: "14px",
                      }}
                      onClick={(e) => handelDateModal(e)}
                    >
                      {" "}
                      <MdModeEdit className="mx-2" />
                      Edit
                    </button>
                  </div>
                </div>
                <h4
                  style={{
                    color: "#1E1E1E",
                    fontWeight: "700",
                    fontFamily: "Nunito",
                    fontSize: "18px",
                    lineHeight: "24.5px",
                  }}
                >
                  Service Deliver Address
                </h4>
                <div className="CostomCard">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          backgroundColor: "#E8F3FD",
                          borderRadius: "5px",
                          width: "34px",
                          height: "34px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "10px",
                        }}
                      >
                        <img src={Marker} alt="Marker Icon" />
                      </div>
                      <p style={{ margin: 0 }}>{SelectedAddress}</p>
                    </div>
                    <button
                      className="d-flex align-items-center "
                      style={{
                        backgroundColor: "#F1F1F1",
                        color: "#1E1E1E",
                        borderRadius: "8px",
                        height: "35px",
                        width: "80px",
                        padding: "8px 8px",
                        fontSize: "14px",
                      }}
                      onClick={(e) => EditMap(e)}
                    >
                      <MdModeEdit className="mx-2" />
                      Edit
                    </button>
                  </div>
                </div>

                <h4
                  style={{
                    color: "#1E1E1E",
                    fontWeight: "700",
                    fontFamily: "Nunito",
                    fontSize: "18px",
                    lineHeight: "24.5px",
                  }}
                >
                  Place Order
                </h4>
                <div className="CostomCard">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <p style={{ margin: 0 }}>
                        Order confirmation will be notify to{" "}
                        <span style={{ fontWeight: "600", color: "#444444" }}>
                          {mobileNumber}
                        </span>
                      </p>
                    </div>
                    <button
                      className="btn btn-primary"
                      onClick={(e) => handelPayment(e)}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
                {isPayment && (
                  <>
                    <h4
                      style={{
                        color: "#1E1E1E",
                        fontWeight: "700",
                        fontFamily: "Nunito",
                        fontSize: "18px",
                        lineHeight: "24.5px",
                      }}
                    >
                      Payment Method
                    </h4>
                    <PaymentMethod
                      setPaymentType={setPaymentType}
                      paymentType={paymentType}
                    />
                  </>
                )}
                <div className="d-flex">
                  <img
                    src={insurance}
                    alt="Insurance"
                    height={30}
                    width={30}
                    margin={"auto"}
                  />
                  <p className="mx-1">
                    By confirming checkout, I agree to the{" "}
                    <span
                      style={{
                        fontWeight: "400",
                        color: "#0079EA",
                        cursor: "pointer",
                      }}
                    >
                      service policy
                    </span>{" "}
                    and{" "}
                    <span
                      style={{
                        fontWeight: "400",
                        color: "#0079EA",
                        cursor: "pointer",
                      }}
                    >
                      refund policy
                    </span>
                    . I also agree to pay full amount of booking.
                  </p>
                </div>
              </div>
              <div className="col-md-5">
                <div className="d-flex justify-content-between">
                  <h4
                    style={{
                      color: "#1E1E1E",
                      fontWeight: "700",
                      fontFamily: "Nunito",
                      fontSize: "18px",
                      lineHeight: "24.5px",
                    }}
                  >
                    Services Added
                  </h4>
                  <button
                    style={{
                      color: "#0085FF",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "21px",
                      fontFamily: "Poppins",
                    }}
                    onClick={handelBack}
                  >
                    Add More
                  </button>
                </div>
                {cart && cart.length > 0 && (
                  <>
                    <div className="CostomCard">
                      <div>
                        {cart.map((item) => (
                          <div key={item.service_id} className="cart-item">
                            <div className="d-flex justify-content-between">
                              <p
                                style={{
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                  color: "#444444",
                                }}
                                className="justify-content-start"
                              >
                                {item.title}
                              </p>

                              <p
                                style={{
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  lineHeight: "22px",
                                  color: "#444444",
                                }}
                                className="justify-content-end"
                              >
                                KES {item.amount}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
                <div>
                  <h4
                    style={{
                      color: "#1E1E1E",
                      fontWeight: "700",
                      fontFamily: "Nunito",
                      fontSize: "18px",
                      lineHeight: "24.5px",
                    }}
                  >
                    Coupon
                  </h4>
                  <div className="CostomCard1">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <div
                          style={{
                            borderRadius: "5px",
                            width: "34px",
                            height: "34px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <img src={Tag} alt="Marker Icon" />
                        </div>
                        <p
                          style={{
                            color: "#444444",
                            fontWeight: "400",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            lineHeight: "21px",
                            margin: 0,
                          }}
                        >
                          {couponCode ? couponCode : "Coupons for off"}
                        </p>
                      </div>
                      {couponCode ? (
                        <button
                          style={{
                            color: "#FF0000",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "21px",
                            fontFamily: "Poppins",
                          }}
                          onClick={HandelRemove}
                        >
                          remove
                        </button>
                      ) : (
                        <IoChevronForward
                          size={20}
                          onClick={() => setCouponModal(true)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <h4
                  style={{
                    color: "#1E1E1E",
                    fontWeight: "700",
                    fontFamily: "Nunito",
                    fontSize: "18px",
                    lineHeight: "24.5px",
                  }}
                >
                  Payment Summary
                </h4>{" "}
                <div className="CostomCard">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <p
                      style={{
                        color: "#444444",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        lineHeight: "21px",
                        margin: 0,
                      }}
                    >
                      Total Charges
                    </p>
                    <p
                      style={{
                        color: "#444444",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        lineHeight: "21px",
                        margin: 0,
                      }}
                    >
                      KES {TotalItems.total}
                    </p>
                  </div>

                  {/*  */}
                  {discount && (
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <p
                        style={{
                          color: "green",
                          fontWeight: "400",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          lineHeight: "21px",
                          margin: 0,
                        }}
                      >
                        Offered Discount{" "}
                        <span
                          style={{
                            color: "green",
                            fontWeight: "400",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            lineHeight: "21px",
                            margin: 0,
                          }}
                        >
                          ({discount?.coupon_discount}%)
                        </span>
                      </p>
                      <p
                        style={{
                          color: "green",
                          fontWeight: "400",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          lineHeight: "21px",
                          margin: 0,
                        }}
                      >
                        - KES {CouponDiscount()}
                      </p>
                    </div>
                  )}

                  {/*  */}
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <p
                      style={{
                        color: "#444444",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        lineHeight: "21px",
                        margin: 0,
                      }}
                    >
                      Fees
                    </p>
                    <p
                      style={{
                        color: "#444444",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        lineHeight: "21px",
                        margin: 0,
                      }}
                    >
                      + KES {catFee}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center ">
                    <p
                      style={{
                        color: "#FF0000",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        lineHeight: "21px",
                        margin: 0,
                      }}
                    >
                      Tax{" "}
                      <span
                        style={{
                          color: "#FF0000",
                          fontWeight: "400",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          lineHeight: "21px",
                          margin: 0,
                        }}
                      >{`(${tax}%)`}</span>
                    </p>
                    <p
                      style={{
                        color: "#FF0000",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        lineHeight: "21px",
                        margin: 0,
                      }}
                    >
                      + KES {calculateTax()}
                    </p>
                  </div>
                  <hr></hr>
                  <div className="d-flex justify-content-between align-items-center">
                    <p
                      style={{
                        color: "#1E1E1E",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        lineHeight: "27px",
                        margin: 0,
                      }}
                    >
                      Total Amount
                    </p>
                    <p
                      style={{
                        color: "#1E1E1E",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        lineHeight: "27px",
                        margin: 0,
                      }}
                    >
                      KES {calculateTotal()}
                    </p>
                  </div>
                </div>
                <button
                  className="btn btn-primary mb-2"
                  style={{ width: "100%" }}
                  onClick={PostJob}
                  disabled={!isPayment}
                >
                  Make Payment
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Change Service Modal Start */}
      <div>
        <Modal isOpen={open} centered style={{ zIndex: 9999 }}>
          <ModalBody>
            <div style={{ textAlign: "center" }}>
              <FaRegQuestionCircle size={50} />
              <p>
                If you Want add Add diffrent service you need to clear all
                selected services
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-outline-primary mx-1"
                onClick={handleClearAndProceed}
              >
                Yes
              </button>
              <button
                className="btn btn-outline-danger mx-1"
                onClick={() => toggle(false)}
              >
                No
              </button>
            </div>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </Modal>
      </div>

      {/* Change Service Modal end */}

      <div style={{ position: "relative" }}>
        <Modal isOpen={dateModal} centered style={{ position: "relative" }}>
          <ModalBody>
            {showMap === false && (
              <div className="container ">
                <div className="d-flex justify-content-between m-1 p-1">
                  <h5>Write us about your job requirement</h5>
                  <MdOutlineCancel
                    onClick={() => setDateModal(false)}
                    style={{
                      cursor: "pointer",
                      width: "30px",
                      height: "30px",
                    }}
                  />
                </div>
                <hr />
                <div className="date-time-picker-container">
                  <div className="date-picker-section">
                    <p
                      className=" mb-3"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "16px",
                        fontFamily: "Poppins",
                        color: "#444444",
                      }}
                    >
                      Available Dates
                    </p>
                    <div className="date-options row">
                      {availableDates.map((date, index) => (
                        <button
                          key={index}
                          className={`date-option col-auto ${
                            selectedDate === date ? "selected" : ""
                          }`}
                          onClick={() => setSelectedDate(date)}
                        >
                          <div>
                            {date.toLocaleDateString("en-US", {
                              weekday: "short",
                            })}
                          </div>
                          <div>{date.getDate()}</div>
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <Demo
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                    setIsPastTime={setIsPastTime}
                    selectedDate={selectedDate}
                  />
                </div>
                <hr />
                <div className="col-12 d-flex justify-content-center">
                  <button
                    className={
                      isPastTime
                        ? "btn btn-outline-primary disabled"
                        : "squreButton"
                    }
                    onClick={
                      firstPart === true
                        ? HandelDateAndMap
                        : HandelDateAndMapOnlyDate
                    }
                  >
                    {firstPart === true ? "Continue" : "Update"}
                  </button>
                </div>
              </div>
            )}
            {showMap === true && demoFlag === true && (
              <>
                <div className="d-flex justify-content-between m-1 p-1">
                  <h5>Select Location</h5>
                  <MdOutlineCancel
                    onClick={() => setDateModal(false)}
                    style={{
                      cursor: "pointer",
                      width: "30px",
                      height: "30px",
                    }}
                  />
                </div>
                <div className="map-container">
                  <MapHelper
                    setSelectedAddress={setSelectedAddress}
                    setFinalCoordinates={setFinalCoordinates}
                    setShowMap={setShowMap}
                    setAddress={setAddress}
                    setFirstPart={setFirstPart}
                    setDateModal={setDateModal}
                    setDemoFlag={setDemoFlag}
                  />
                </div>
              </>
            )}
          </ModalBody>
        </Modal>

        <Modal isOpen={Couponmodal} centered>
          <ModalBody>
            <div className="d-flex justify-content-between">
              <h6
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                  lineHeight: "24px",
                  fontFamily: "Poppins",
                  color: "#2F2F2F",
                  margin: 0,
                }}
              >
                Add Coupon
              </h6>
              <MdOutlineCancel
                onClick={() => {
                  setCouponModal(false);
                }}
                style={{
                  cursor: "pointer",
                  width: "30px",
                  height: "30px",
                  color: "#2F2F2F",
                }}
              />
            </div>
            <hr></hr>
            <div className="custom-modal-body">
              <div className="custom-input-group">
                <input
                  type="text"
                  className="custom-form-control"
                  placeholder="Enter Coupon Code"
                  value={coupon}
                  onChange={handleInputChange}
                  aria-label="Enter Coupon Code"
                />
                <button
                  className={`custom-btn custom-btn-primary ${
                    coupon ? "enabled" : "disabled"
                  }`}
                  type="button"
                  disabled={!coupon}
                  onClick={handleApplyCoupon}
                >
                  Apply
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>

      {/*  */}
    </>
  );
};

export default VenderDetailsPage;

// {
//   "status": 200,
//   "msg": "Vendor Details.",
//   "vendor_id": 94,
//   "username": "Jelvin@11",
//   "is_favourite": false,
//   "name": "Kelvin",
//   "description": "",
//   "profile_img": "https://api.suluhu.net/media/ProfileImages/Kelvin_profile_image.jpg",
//   "service_image": {
//       "image_1": "",
//       "image_2": "",
//       "image_3": ""
//   },
//   "ratings": 3.5,
//   "location": "B/14/4, Ravi Nagar, Bharat Nagar, Nagpur, Maharashtra 440010, India",
//   "lat": 21.1526422,
//   "long": 79.0569965,
//   "nearKm": "4.7",
//   "categories_list": [
//       {
//           "cat_id": 3,
//           "cat_tax": 18,
//           "catFees": 150.0,
//           "cat_name": "Home Services",
//           "cat_img": "https://api.suluhu.net/media/CategoryImages/unnamed.jpg",
//           "sub_cat_list": [
//               {
//                   "sub_cat_id": 1,
//                   "sub_cat_name": "Home Insurance",
//                   "sub_cat_img": "https://api.suluhu.net/media/SubCategoryImages/655dd40aa1d11_download.jpg",
//                   "available_service": [
//                       {
//                           "service_id": 32,
//                           "category": "Home service available  ",
//                           "title": "Home service available  ",
//                           "image": "https://api.suluhu.net/media/service_provider_images/image_cropper_1719989484666.jpg",
//                           "amount": "259",
//                           "include_points": [
//                               "service provider"
//                           ],
//                           "exclude_points": [
//                               "service provider"
//                           ]
//                       }
//                   ]
//               },
//               {
//                   "sub_cat_id": 25,
//                   "sub_cat_name": "Cleaning",
//                   "sub_cat_img": "https://api.suluhu.net/media/SubCategoryImages/8634252.png",
//                   "available_service": [
//                       {
//                           "service_id": 33,
//                           "category": "CLEANING SERVICE AVALABLE",
//                           "title": "CLEANING SERVICE AVALABLE",
//                           "image": "https://api.suluhu.net/media/service_provider_images/image_cropper_1720072786360.jpg",
//                           "amount": "158",
//                           "include_points": [
//                               "1"
//                           ],
//                           "exclude_points": [
//                               "2"
//                           ]
//                       }
//                   ]
//               }
//           ]
//       },
//       {
//           "cat_id": 6,
//           "cat_tax": 16,
//           "catFees": 100.0,
//           "cat_name": "Professional Services",
//           "cat_img": "https://api.suluhu.net/media/CategoryImages/655ddd204866e_download_XNJFq8V.jpg",
//           "sub_cat_list": [
//               {
//                   "sub_cat_id": 27,
//                   "sub_cat_name": "Painting",
//                   "sub_cat_img": "https://api.suluhu.net/media/SubCategoryImages/Painting.png",
//                   "available_service": [
//                       {
//                           "service_id": 30,
//                           "category": "House painting",
//                           "title": "House painting",
//                           "image": "https://api.suluhu.net/media/service_provider_images/image_cropper_1719983260212.jpg",
//                           "amount": "250",
//                           "include_points": [
//                               "wall painting",
//                               "Door painting"
//                           ],
//                           "exclude_points": [
//                               "All Finishing"
//                           ]
//                       }
//                   ]
//               },
//               {
//                   "sub_cat_id": 28,
//                   "sub_cat_name": "Moving",
//                   "sub_cat_img": "https://api.suluhu.net/media/SubCategoryImages/Moving.png",
//                   "available_service": [
//                       {
//                           "service_id": 31,
//                           "category": "packers and moving service",
//                           "title": "packers and moving service",
//                           "image": "https://api.suluhu.net/media/service_provider_images/image_cropper_1719989354960.jpg",
//                           "amount": "250",
//                           "include_points": [
//                               "data data"
//                           ],
//                           "exclude_points": [
//                               "Data data"
//                           ]
//                       }
//                   ]
//               }
//           ]
//       }
//   ]
// }

// {/* <div className="row">
//   <div className="container offset-lg-1">
//     {avalableCat && avalableServices.length > 0 ? (
//       avalableServices.map((service) => (
//         <div key={service.service_id} className="row">
//           <div className="col-8">
//             <p
//               style={{
//                 fontWeight: "600",
//                 color: "#444444",
//                 fontSize: "16px",
//                 lineHeight: "24px",
//               }}
//             >
//               {service.title}
//             </p>
//             {/* <p style={{ fontWeight: "600", color: "#444444", fontSize: "16px", lineHeight: "24px"}}>Include</p> */}
//             {service.include_points.map((point) => (
//               <ul>
//                 <li
//                   style={{
//                     color: "#868686",
//                     fontSize: "13px",
//                     lineHeight: "9.5px",
//                   }}
//                 >
//                   {point}
//                 </li>
//               </ul>
//             ))}
//             {/* <p style={{ fontWeight: "600", color: "#444444", fontSize: "16px", lineHeight: "24px"}}>Exclude</p>
//   {service.exclude_points.map((point) => (
//      <ul>
//      <li style={{color: "#868686", fontSize: "13px", lineHeight: "8.5px"}}>{point}</li>
//      </ul>
//   ))} */}
//           </div>
//           <div className="col-3">
//             <div className="float-end">
//               <img
//                 src={service.image}
//                 alt={"service Image"}
//                 width="100"
//                 height="100"
//                 style={{ borderRadius: "5px" }}
//               />
//               {showControls ? (
//                 <div
//                   style={{
//                     border: "1px solid #007bff",
//                     borderRadius: "5px",
//                     overflow: "hidden",
//                     marginTop: "10px",
//                   }}
//                 >
//                   <div
//                     className="quantity-control"
//                     style={{
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                     }}
//                   >
//                     <button
//                       className="decrement"
//                       onClick={decrementQuantity}
//                       style={{ borderRadius: "0", margin: "0" }}
//                     >
//                       -
//                     </button>
//                     <span id="quantity" style={{ margin: "0 10px" }}>
//                       {quantity}
//                     </span>
//                     <button
//                       className="increment"
//                       onClick={incrementQuantity}
//                       style={{ borderRadius: "0", margin: "0" }}
//                     >
//                       +
//                     </button>
//                   </div>
//                 </div>
//               ) : (
//                 <button className="add-button" onClick={handleAddClick}>
//                   Add
//                 </button>
//               )}
//             </div>
//           </div>
//         </div>
//       ))
//     ) : (
//       <div>No Data Available2 ..................</div>
//     )}
//   </div>
// </div>; */}
