import React, { useEffect } from "react";
import ProfileForm from "../../components/Profile/ProfileForm";
import { viewProfile } from "../../features/Profile/profileSlice";
import { getUser } from "../../utils/authUtils";
import { useDispatch, useSelector } from "react-redux";

const EditProfile = () => {
  const dispatch = useDispatch();
  const { user_id } = getUser();
  const { loading } = useSelector((state) => state.profile);
  function getUserProfile() {
    dispatch(viewProfile({ user_id: user_id }));
  }
  // useEffect(() => {
  //   getUserProfile()
  // }, [dispatch, user_id]);
  return (
    <>
      <ProfileForm getUserProfile={getUserProfile} loading={loading} />
    </>
  );
};

export default EditProfile;
