import React from "react";
import { motion } from "framer-motion";
import {
  Dialog,
  DialogContent,
  Button,
  Box,
  Typography,
} from "@mui/material";
import deleteIcon from "../../assets/images/deleteIcon.png";
import { useTheme } from "@mui/material/styles";
const FailedMoadal = ({ open, handleClose ,message }) => {
    const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperProps={{
        component: motion.div,
        initial: { opacity: 0, scale: 0.75 },
        animate: { opacity: 1, scale: 1 },
        exit: { opacity: 0, scale: 0.75 },
        transition: { duration: 0.5 },
        sx: {
          borderRadius: "1rem",
          maxWidth: "450px",
          mx: "auto",
          p: 2,
          textAlign: "center",
          position: "relative",
          overflow: "visible",
        },
       
      }}
    >
      <DialogContent sx={{ textAlign: "center" }}>
        <Box
          sx={{
            backgroundColor: "primary.main",
            borderRadius: "50%",
            boxShadow: 3,
            width: "6rem",
            height: "6rem",
            margin: "0 auto 1rem auto",
            position: "absolute",
            top: "-4rem",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={deleteIcon} alt="Suluhu Logo" style={{ width: "5rem" }} />
        </Box>
        <Typography variant="h6" gutterBottom>
        </Typography>
        <Typography variant="body2" sx={{ mb: 3 , color:theme.palette.secondary.main }}>
          {message}
        </Typography>
        <Button variant="contained" onClick={handleClose} sx={{ mt: 1 }}>
          Close
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default FailedMoadal;
