import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function withRouter(Componant) {
  function ComponantWithRouterProps(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Componant {...props} router={{ location, navigate, params }} />;
  }
  return ComponantWithRouterProps;
}

export default withRouter;
