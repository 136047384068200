import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBroadcastList } from "../../features/Broadcast/broadcastListSlice";
import { getUser } from "../../utils/authUtils";
import BroadCast from "../Order/BroadCast";
import Loader from "../../components/comman/Loader";

const BroadCastList = () => {
  const { user_id } = getUser();
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.broadcastList);

  useEffect(() => {
    dispatch(fetchBroadcastList({ user_id }));
  }, [dispatch, user_id]);
  console.log(data?.broadcast_list, "broadcast_list");
  if (loading) return <Loader />;
  return (
    <>
      <BroadCast data={data?.broadcast_list} />
    </>
  );
};

export default BroadCastList;
