import React from "react";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import {
  CustomAvatar,
  CustomImageDialog,
} from "../../pages/Order/CancelJobDialog";

const DeleteAccountModal = ({ open, onClose, onConfirm }) => {
  return (
    <CustomImageDialog open={open} onClose={onClose}>
      <DialogTitle id="cancel-dialog-title" style={{ marginTop: "1.4rem" }}>
        <CustomAvatar>
          <PersonRemoveIcon style={{ fontSize: 70 }} />
        </CustomAvatar>
        Delete Account!
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {" "}
          Are you sure you want to delete your account?
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        <Button variant="outlined" onClick={onConfirm} color="primary">
          Yes
        </Button>
        <Button variant="outlined" onClick={onClose} color="error">
          No
        </Button>
      </DialogActions>
    </CustomImageDialog>
  );
};

export default DeleteAccountModal;
