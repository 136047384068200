import React from "react";
import notFound from "../../assets/images/404.jpg";
import { Box } from "@mui/material";
import { BackButton } from "../../components/UI/Button";

const NotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems:"center"
      }}
    >
      <Box>
        <BackButton color={"#000"} />Go To Back
      </Box>
      <img src={notFound} alt="not found" />
    </Box>
  );
};

export default NotFound;
