import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";
import { endpoints } from "../../config/config";

export const updateMobileEmail = createAsyncThunk(
  "user/updateMobileEmail",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        endpoints.update_mobile_email,
        userData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyUpdateRequestOtp = createAsyncThunk(
  "user/verifyUpdateRequestOtp",
  async (otpData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        endpoints.verify_update_request_otp,
        otpData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "user/deleteAccount",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(endpoints.delete_account, {
        user_id: userId,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    loading: false,
    error: null,
    verificationLoading: false,
    verificationError: null,
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateMobileEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMobileEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(updateMobileEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Error fetching ";
      })
      .addCase(verifyUpdateRequestOtp.pending, (state) => {
        state.verificationLoading = true;
      })
      .addCase(verifyUpdateRequestOtp.fulfilled, (state, action) => {
        state.verificationLoading = false;
      })
      .addCase(verifyUpdateRequestOtp.rejected, (state, action) => {
        state.verificationLoading = false;
        state.verificationError = action.payload;
      })
      .addCase(deleteAccount.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteAccount.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;
