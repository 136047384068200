import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { endpoints } from "../../config/config";
import axiosInstance, { axiosFormDataInstance } from "../../api/axiosInstance";

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (profileData, { rejectWithValue }) => {
    try {
      const response = await axiosFormDataInstance.post(
        endpoints.update_profile,
        profileData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const viewProfile = createAsyncThunk(
  "profile/viewProfile",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        endpoints.view_profile,
        userData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    userProfile: null,
    updateUserProfile: null,
    updateProfileLoading: false,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateProfile.pending, (state) => {
        state.updateProfileLoading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.updateProfileLoading = false;
        state.updateUserProfile = action.payload;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.updateProfileLoading = false;
        state.error = action.error.message || "Error fetching ";
      })
      .addCase(viewProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(viewProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.userProfile = action.payload?.payload;
      })
      .addCase(viewProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Error fetching ";
      });
  },
});

export default profileSlice.reducer;
