import React, { Suspense, lazy } from "react";
import Loader from "./Loader";

const lazyLoadComponent = (importFunction) => {
  const LazyComponant = lazy(importFunction);
  return (props) => (
    <Suspense fallback={<Loader/>}>
      <LazyComponant {...props} />
    </Suspense>
  );
};

export default lazyLoadComponent;
