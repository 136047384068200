import React from "react";
import Box from "@mui/material/Box";
import Loader from "./Loader";

const withLoader =
  (Component) =>
  ({ loading, ...props }) =>
    (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
          border: "1px solid #ddd",
          borderRadius: "8px",
          maxWidth: "900px",
          margin: "auto",
          overflow: "hidden",
          position: "relative",
        }}
      >
        {loading && <Loader />}

        <Box sx={{ opacity: loading ? 0.5 : 1, width: "100%" }}>
          <Component {...props} />
        </Box>
      </Box>
    );
export default withLoader;
