import {
  styled,
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  IconButton,
  Card,
  CardContent,
  Avatar,
  Divider,
} from "@mui/material";
export const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "16px",
  backgroundColor: "#1976d2",
  background: "linear-gradient(90deg, #0079EA 0%, #004484 100%)",
  color: "#fff",
  padding: "16px",
  height: "180px",
});
export const StyledOrderBox = styled(Box)({
  padding: "20px",
  background: "#ffffff",
  width: "90%",
  margin: "auto",
  zIndex: 10,
  marginTop: "-100px",
  borderRadius: "1rem",
});
export const StyledOrderBox2 = styled(Box)({
  padding: "20px",
  background: "#ffffff",
  width: "90%",
  margin: "auto",
  zIndex: 10,
  marginTop: "-100px",
  borderRadius: "1rem",
  border: "1px solid #ccc",
});

export const StyleDateTimeBox = styled(Box)({
  display: "flex",
  padding: "1.5rem",
  border: "1px solid rgba(60, 64, 67, 0.15)",
  borderRadius: ".5rem",
});

export const StyleTypography = styled(Typography)({
  fontFamily: "Nunito",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "24.55px",
  textAlign: "left",
  marginTop: "16px",
});

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "#1976d2",
  color: "#fff",
  margin: "1rem",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));