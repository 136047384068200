import React from "react";
import Chat from "../../components/Chat/Chat";

const ChatRoom = () => {
  return (
    <>
      <Chat />
    </>
  );
};

export default ChatRoom;
