import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import GooglePlayIcon from "../../assets/images/ios-android 1.png";
import AppStoreIcon from "../../assets/images/ios-android 2.png";
import SuluhuLogo from "../../assets/images/footerLogo.png";
import { List, ListItem } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useNavigate } from "react-router-dom";

const logoStyle = {
  width: "140px",
  height: "auto",
};

const listItemStyle = {
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  paddingLeft: "0px",
  // gap: 1,
  transition: "all 0.3s ease",
  "&:hover": {
    color: "#1976d2",
    "& .defaultIcon": {
      display: "none",
    },
    "& .hoverIcon": {
      display: "block",
    },
    "& .listItemText": {
      transform: "translateX(5px)",
    },
  },
};

const listItemIconStyle = {
  fontSize: "1rem",
  transition: "all 0.3s ease",
};

const listItemHoverIconStyle = {
  display: "none",
};

const listItemTextStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  marginLeft: "8px",
  transition: "transform 0.3s ease",
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {"© Copyright "}
      <Link color="inherit" href="#">
        Suluhu
      </Link>{" "}
      {new Date().getFullYear()}. All rights reserved.
    </Typography>
  );
}

const items = [
  { text: "Home", path: "/dashboard" },
  { text: "About Us", path: "/about" },
  { text: "Contact Us", path: "/contact" },
];
export default function Footer() {
  const navigate = useNavigate();

  const handleClick = (path) => {
    if (window.location.pathname === path) {
      window.scrollTo(0, 0);
    } else {
      navigate(path);
    }
  };
  return (
    <Box component="footer" sx={{ backgroundColor: "#e7f3ff", pt: 6, pb: 6 }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: { xs: "center", md: "flex-start" },
            textAlign: { xs: "center", md: "left" },
            gap: { xs: 4, md: 0 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <img src={SuluhuLogo} alt="Suluhu Logo" style={logoStyle} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <Typography
              variant="h6"
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              Our Company
            </Typography>
            <List>
              {items.map((item, index) => (
                <ListItem
                  key={index}
                  sx={listItemStyle}
                  onMouseEnter={(e) => {
                    e.currentTarget.querySelector(".hoverIcon").style.display =
                      "block";
                    e.currentTarget.querySelector(
                      ".defaultIcon"
                    ).style.display = "none";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.querySelector(".hoverIcon").style.display =
                      "none";
                    e.currentTarget.querySelector(
                      ".defaultIcon"
                    ).style.display = "block";
                  }}
                  onClick={() => handleClick(item.path)}
                >
                  <ArrowForwardIosIcon
                    sx={{ ...listItemIconStyle, display: "block" }}
                    className="defaultIcon"
                  />
                  <KeyboardDoubleArrowRightIcon
                    sx={{ ...listItemIconStyle, ...listItemHoverIconStyle }}
                    className="hoverIcon"
                  />
                  <Typography sx={listItemTextStyle} className="listItemText">
                  {item.text}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
              gap: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              Other Links
            </Typography>
            <List>
              {[
                { text: "Terms & Conditions", path: "/terms" },
                { text: "Privacy Policy", path: "/privacy" },
              ].map((text, index) => (
                <ListItem
                  key={index}
                  sx={listItemStyle}
                  onMouseEnter={(e) => {
                    e.currentTarget.querySelector(".hoverIcon").style.display =
                      "block";
                    e.currentTarget.querySelector(
                      ".defaultIcon"
                    ).style.display = "none";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.querySelector(".hoverIcon").style.display =
                      "none";
                    e.currentTarget.querySelector(
                      ".defaultIcon"
                    ).style.display = "block";
                  }}
                  onClick={() => navigate(text.path)}
                >
                  <ArrowForwardIosIcon
                    sx={{ ...listItemIconStyle, display: "block" }}
                    className="defaultIcon"
                  />
                  <KeyboardDoubleArrowRightIcon
                    sx={{ ...listItemIconStyle, ...listItemHoverIconStyle }}
                    className="hoverIcon"
                  />
                  <Typography sx={listItemTextStyle} className="listItemText">
                    {text.text}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
              gap: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              Social Links
            </Typography>
            <Stack direction="row" spacing={1}>
              <IconButton href="#" aria-label="Facebook">
                <FacebookIcon />
              </IconButton>
              <IconButton href="#" aria-label="Twitter">
                <TwitterIcon />
              </IconButton>
              <IconButton href="#" aria-label="Instagram">
                <InstagramIcon />
              </IconButton>
              <IconButton href="#" aria-label="LinkedIn">
                <LinkedInIcon />
              </IconButton>
            </Stack>
            <Stack direction="row" spacing={1} mt={2}>
              <Link href="#" color="inherit">
                <img
                  src={GooglePlayIcon}
                  alt="Google Play Store"
                  style={{ width: 120, height: "auto" }}
                />
              </Link>
              <Link href="#" color="inherit">
                <img
                  src={AppStoreIcon}
                  alt="Apple App Store"
                  style={{ width: 120, height: "auto" }}
                />
              </Link>
            </Stack>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "center" },
            alignItems: "center",
            borderTop: "1px solid #E0E0E0",
            mt: 4,
            pt: 2,
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 2, md: 0 },
          }}
        >
          <Copyright />
        </Box>
      </Container>
    </Box>
  );
}
