import React, { memo, useEffect, useState } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  IconButton,
  Card,
  CardContent,
  styled,
  Avatar,
  Divider,

} from "@mui/material";

import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrdersList } from "../../features/Order/ordersSlice";
import { getUser } from "../../utils/authUtils";
import Loader from "../../components/comman/Loader";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { StyledBox, StyledOrderBox } from "../../components/UI/StyleBox";
import { BackButton } from "../../components/UI/Button";
import { formatDateTime } from "../../utils/dateUtils";
import MotionCard from "../../components/MotionCard/MotionCard";
import "./Search.css";
import { IoIosSearch } from "react-icons/io";

export const OrderCard = memo(
  ({
    broadcast,
    date,
    time,
    Id,
    name,
    jobNo,
    list,
    status,
    totalAmount,
    discription,
  }) => {
    const dates = formatDateTime(date, time);

    const navigate = useNavigate();

    return (
      <MotionCard>
        <Card
          sx={{
            backgroundColor: "#FFFFFF",
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
            borderRadius: "8px",
            padding: "0.5rem",
            cursor: "pointer",
            width: "100%",
            maxWidth: 520,
            margin: "16px auto",
          }}
          onClick={() =>
            navigate(`/${broadcast ? "broadcast-detail" : "job-details"}/${Id}`)
          }
        >
          <CardContent>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box display={"flex"} alignItems={"center"}>
                {broadcast ? (
                  <Typography
                    variant="h6"
                    component="div"
                    color="primary"
                    sx={{ fontWeight: "bold", fontSize: "1rem" }}
                  >
                    Job No: {jobNo}
                  </Typography>
                ) : (
                  <>
                    <Avatar sx={{ mr: 1, width: 24, height: 24 }} />
                    <Typography
                      variant="h6"
                      component="div"
                      color="primary"
                      sx={{ fontWeight: "bold", fontSize: "1rem" }}
                    >
                      {name}
                    </Typography>{" "}
                  </>
                )}
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <EventIcon fontSize="small" sx={{ mr: 1 }} />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontSize: "0.875rem" }}
                  >
                    {dates.formattedDate}
                  </Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                  <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontSize: "0.875rem" }}
                  >
                    {dates.formattedTime}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {broadcast ? (
              <>
                <Box display="flex" alignItems="center" mt={1}>
                  <>
                    <img
                      src={list?.category?.category_image}
                      alt={list?.category?.category_name}
                      style={{ width: 28 }}
                    />
                    <Typography
                      variant="h6"
                      color="black"
                      sx={{ fontSize: "1.2rem", marginLeft: 1 }}
                    >
                      {list?.category?.category_name}
                    </Typography>
                  </>
                </Box>
                <Box display="flex" alignItems="center" ml={4}>
                  <>
                    <img
                      src={list?.sub_category?.sub_category_image}
                      alt={list?.sub_category?.sub_category_name}
                      style={{ width: 22 }}
                    />
                    <Typography
                      variant="h6"
                      color="secondary"
                      sx={{ fontSize: "0.9rem", margin: 1 }}
                    >
                      {list?.sub_category?.sub_category_name}
                    </Typography>
                  </>
                </Box>
              </>
            ) : (
              <>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ textAlign: "start", fontSize: "0.875rem" }}
                >
                  Order No: {jobNo}
                </Typography>
                <Box display="flex" alignItems="center" mt={1} mb={1}>
                  {list?.map((service) => (
                    <>
                      <Avatar
                        src={service.service_image}
                        alt={service?.service_name}
                        sx={{ mr: 1, width: 24, height: 24 }}
                      />
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ fontSize: "0.875rem" }}
                      >
                        {service?.service_name}
                      </Typography>
                    </>
                  ))}
                </Box>
              </>
            )}
            <p style={{ fontWeight: "200", color: "#817e7e", fontSize: "12px" }}>{discription}</p>
            <Divider
              sx={{
                borderColor: "orange",
                borderStyle: "dashed",
                my: 1,
              }}
            />
            <Box display="flex" justifyContent={"space-between"}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "0.875rem" }}
              >
                Service Budget: KES {totalAmount}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: status === "CONFIRMED" ? "#FF7A00" : "#E2A700",
                  border:
                    status === "CONFIRMED"
                      ? "1px solid #FF7A00"
                      : "1px solid #E2A700",
                  borderRadius: "4px",
                  padding: "2px 8px",
                  fontWeight: "bold",
                  fontSize: "0.875rem",
                }}
              >
                {status}
              </Typography>
            </Box>
          </CardContent>
        </Card>{" "}
      </MotionCard>
    );
  }
);

const MyOrderList = () => {
  const navigate = useNavigate();
  const { user_id } = getUser();
  const dispatch = useDispatch();
  const { userOrders, loading } = useSelector((state) => state.orders);
  const [isSearch, setIsSearch] = useState(false);
  const handleSearchClick = () => {
    setIsSearch(true);
  };
  useEffect(() => {
    dispatch(fetchOrdersList({ user_id: user_id }));
  }, [dispatch]);

  useEffect(() => {
    // Only apply the back button prevention on the protected page
    if (location.pathname === "/my-order") {
      window.history.pushState(null, "", window.location.href);

      const handlePopState = (event) => {
        window.history.pushState(null, "", window.location.href);
        navigate(window.location.pathname);
      };

      window.addEventListener("popstate", handlePopState);

      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }
  }, []);

  const handleBack = () => {
    navigate("/dashboard");
  };
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };


  const filteredOrders = (userOrders?.order_list || []).filter((order) => {
    const vendorName = order.vendor_name ? order.vendor_name.toLowerCase() : '';
    const jobNo = order.job_no ? order.job_no : '';
    const query = searchQuery.toLowerCase();
  
    return vendorName.includes(query) || jobNo.includes(query);
  });
  
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e) => {
    if (e.target.value === "") {
      setIsFocused(false);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    handleSearchChange(e); // Call handleSearchChange when input changes
  };

  const toggleFocus = () => {
    if (isFocused && inputValue === "") {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  };

  if (loading) return <Loader />;

  return (
    <>
      <StyledBox>
        <Box>
          <Typography variant="h5" sx={{ color: "#FFFFFF", fontWeight: 600 }}>
            My Order
          </Typography>
        </Box>
        <Box>
          <form className={`search ${isFocused ? "focused" : ""}`}>
            <input
              type="number"
              className={`search__input ${isFocused ? "focused" : ""}`}
              placeholder="Search..."
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={inputValue}
              onChange={handleInputChange}
            />
            <button
              type="button"
              className="search__button "
              onClick={toggleFocus}
            >
              <IoIosSearch size={23} color="#FFFFFF" />
            </button>
          </form>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon sx={{ color: "#FFFFFF" }} />
          </IconButton>
        </Box>
        {/* {isSearch && (
          <Box>
            <input type="text" placeholder="Search..." />
          </Box>
        )} */}
      </StyledBox>
      <StyledOrderBox>
        <Grid container spacing={3}>
          {Array.isArray(filteredOrders) && filteredOrders.length > 0 ? (
            filteredOrders.map((order, index) => (
              <Grid item xs={12} md={6} key={index}>
                <OrderCard
                  date={order.date_time}
                  Id={order.order_id}
                  name={order.name}
                  jobNo={order.job_no}
                  list={order?.service_list}
                  totalAmount={order.total_amount}
                  status={order.job_status}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  marginTop: 4,
                  padding: 2,
                  color: "gray",
                }}
              >
                {/* {userOrders?.order_list} */}
                Your order list empty
              </Typography>{" "}
            </Grid>
          )}
        </Grid>
      </StyledOrderBox>
    </>
  );
};

export default MyOrderList;
