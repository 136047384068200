import React from "react";
import { NavLink } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

const HomeNavLink = () => (
  <NavLink to="/dashboard" style={{ textDecoration: "none" }}>
    <Box display="flex" alignItems="center">
      <HomeIcon style={{ color: "#868686", marginRight: "5px" }} />
      <Typography
        variant="h6"
        style={{
          color: "#868686",
          fontFamily: "Poppins",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",
          textAlign: "left",
        }}
      >
        Home
      </Typography>
    </Box>
  </NavLink>
);

export default HomeNavLink;
