import React, { useState, useEffect } from "react";
import { TimePicker } from "react-ios-time-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./TimePickerStyles.css";
import { Modal, ModalBody } from "reactstrap";

const Demo = ({
  setSelectedTime,
  selectedTime,
  setIsPastTime,
  selectedDate,
}) => {
  const [currentTime, setCurrentTime] = useState("");
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    setCurrentTime(formattedTime);
  }, [setSelectedTime]);

  const convertTo24HourFormat = (timeValue) => {
    const [time, modifier] = timeValue.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours.toString().padStart(2, "0")}:${minutes}`;
  };

  const onChange = (timeValue) => {
    const now = new Date();
    const selectedDateTime = new Date(selectedDate);
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();
    const [selectedHours, selectedMinutes] = convertTo24HourFormat(timeValue)
      .split(":")
      .map(Number);
    selectedDateTime.setHours(selectedHours, selectedMinutes);

    if (selectedDate.toDateString() === now.toDateString()) {
      if (
        selectedHours > currentHours ||
        (selectedHours === currentHours && selectedMinutes >= currentMinutes)
      ) {
        setIsPastTime(false);
        setSelectedTime(timeValue);
      } else {
        toggle();
        setIsPastTime(true);
      }
    } else {
      setIsPastTime(false);
      setSelectedTime(timeValue);
    }
  };

  return (
    <>
      <Modal isOpen={open} centered style={{ zIndex: 9999 }}>
        <ModalBody>
          <div style={{ textAlign: "center" }}>
            <p>You cannot select a past time.</p>
          </div>
          <div className="d-flex justify-content-center">
            <button className="btn btn-outline-danger mx-1" onClick={toggle}>
              Close
            </button>
          </div>
        </ModalBody>
      </Modal>
      <p
        style={{
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "16px",
          fontFamily: "Poppins",
          color: "#444444",
        }}
      >
        Select start time of service{" "}
        <span
          style={{
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "14px",
            fontFamily: "Poppins",
          }}
        >
          (Your services will take approx. 30 min)
        </span>
      </p>
      <div className="time-picker-container">
        <div className="d-flex justify-content-center">
          <TimePicker
            onChange={onChange}
            value={selectedTime}
            popupClassName="popup"
            use12Hours
          />
        </div>
      </div>
    </>
  );
};

export default Demo;
