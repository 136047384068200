import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "../features/auth/authSlice";
import customerReducer from "../features/customer/customerSlice";
import nearbyVendorsReducer from "../features/dashboard/nearbyVendorsSlice";
import profileReducer from "../features/Profile/profileSlice";
import userMobileEmailUpdateReducer from "../features/Profile/userSlice";
import ordersReducer from "../features/Order/ordersSlice";
import favoriteVendorsReducer from "../features/Vendors/favoriteVendorsSlice";
import vendorDetailsReducer from "../features/Vendors/vendorDetailsSlice";
import notificationsSlice from "../features/Notification/notificationsSlice";
import broadcastListReducer from "../features/Broadcast/broadcastListSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  customer: customerReducer,
  nearbyVendors: nearbyVendorsReducer,
  profile: profileReducer,
  user: userMobileEmailUpdateReducer,
  orders: ordersReducer,
  favoriteVendors: favoriteVendorsReducer,
  vendorDetails: vendorDetailsReducer,
  notification: notificationsSlice,
  broadcastList: broadcastListReducer,

  // for Firebase
  // auth: authReducer,
  // chat: chatReducer,
});

export default rootReducer;
