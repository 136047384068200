import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import lazyLoadComponent from "../components/comman/lazyLoadComponent";
import Authmiddleware from "./Authmiddleware";
import JobBroadCastPage from "../pages/PostBroadCast/JobBroadCastPage";
import PostBroadcastJobPage from "../pages/PostBroadCast/ViewS";
import EditProfile from "../pages/Profile/EditProfile";
import DashboardLayout from "../components/Dashboard/DashboardLayout";
import MyOrder from "../pages/Order/MyOrder";
import BroadCastList from "../pages/PostBroadCast/BroadCastList";
import VenderDetailsPage from "../pages/VenderDetails/VenderDetailsPage";
import JobDetails from "../pages/Order/JobDetails";
import AssignPage from "../pages/Order/AssignPage";
// import MyOrder from "../pages/Order/MyOrder";
// import JobBroadCast from "../pages/PostBroadCast/JobBroadCast";
const LoginPage = lazyLoadComponent(() => import("../pages/Auth/LoginPage"));
const SignupPage = lazyLoadComponent(() => import("../pages/Auth/SignupPage"));
const DashboardPage = lazyLoadComponent(() =>
  import("../pages/Dashboard/DashboardPage")
);
import V1 from "../pages/PostBroadCast/ViewS";
import FavouriteVendors from "../pages/Vendors/FavouriteVendors";
import FavouriteVendorDetail from "../pages/Vendors/FavouriteVendorDetail";
import PaymentMethod from "../pages/Payment/Payment";
import ChatRoom from "../pages/Chat/ChatRoom";
import NotFound from "../pages/404/NotFound";
import BroadCastDetail from "../pages/Order/BroadCastDetail";
import ChatAssign from "../pages/VenderDetails/ChatAssign";
import TermsConditions from "../pages/FooterPages/TermsConditions";
import PrivacyPolicy from "../pages/FooterPages/PrivacyPolicy";
import AboutUs from "../pages/FooterPages/AboutUs";
import ContactUs from "../pages/FooterPages/ContactUs";

const LandingPage = lazyLoadComponent(() => import("../LandingPage"));

const googleMapRoutes = [
  { path: "/dashboard", element: <DashboardPage /> },
  { path: "/chat/:vendor_id?", element: <ChatRoom /> },
  { path: "/assign-job", element: <AssignPage /> },
  { path: "/Job-Summary", element: <ChatAssign /> },
];
const privateRoutes = [
  { path: "/landing-page", element: <LandingPage /> },
  { path: "/edit-profile", element: <EditProfile /> },
  { path: "/vendors-list", element: <FavouriteVendors /> },
  {
    path: "/favourite-vendor-detail/:vendor_id?",
    element: <FavouriteVendorDetail />,
  },
  {
    path: "/my-order",
    element: <MyOrder />,
  },
  { path: "/job-details/:order_id", element: <JobDetails /> },
  { path: "/broadcast-list", element: <BroadCastList /> },
  { path: "/broadcast-detail/:broadcast_id?", element: <BroadCastDetail /> },

  { path: "/v1", element: <V1 /> },
  { path: "/payment", element: <PaymentMethod /> },
  { path: "/job-broadcast", element: <JobBroadCastPage /> },
  { path: "/vender-details", element: <VenderDetailsPage /> },
  { path: "/terms", element: <TermsConditions /> },
  { path: "/privacy", element: <PrivacyPolicy /> },
  { path: "/about", element: <AboutUs /> },
  { path: "/contact", element: <ContactUs /> },
  
  //copy Of vender-details comming from Chat Assign Page
];

const renderRoutes = (routes) => {
  return routes.map(({ path, element, children }) => (
    <Route key={path} path={path} element={element}>
      {children && renderRoutes(children)}
    </Route>
  ));
};

const PrivateRoutes = () => (
  <Routes>
    <Route element={<Authmiddleware />}>
      <Route element={<DashboardLayout />}>{renderRoutes(privateRoutes)}</Route>
    </Route>
  </Routes>
);
const GoogleMapRoutes = () => (
  <Routes>
    <Route element={<Authmiddleware />}>{renderRoutes(googleMapRoutes)}</Route>
  </Routes>
);

const AppRouter = () => (
  <>
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="*" element={<NotFound />} />
      {googleMapRoutes?.map(({ path, element }) => (
        <Route
          key={path}
          path={path}
          element={<Authmiddleware>{element}</Authmiddleware>}
        />
      ))}
      {privateRoutes?.map(({ path, element }) => (
        <Route
          key={path}
          path={path}
          element={
            <Authmiddleware>
              <DashboardLayout>{element}</DashboardLayout>
            </Authmiddleware>
          }
        />
      ))}
    </Routes>
  </>
);

export default AppRouter;
