import React, { useEffect, useState } from "react";
import { StyledBox, StyledOrderBox2 } from "../../components/UI/StyleBox";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { BackButton } from "../../components/UI/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { CgNotes } from "react-icons/cg";
import { RiMapPinFill } from "react-icons/ri";
import { MdDateRange } from "react-icons/md";
import { IoTimeOutline } from "react-icons/io5";
import vectorImage from "../../assets/images/VectorImg1.png";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const AssignPage = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { vendorDetail, JobDetail } = location.state || {};

  const [categoryDetails, setCategoryDetails] = useState({
    catImage: "",
    catName: "",
    subCatImage: "",
    subCatName: "",
    venderName: "",
    serviceDetails: "",
    jobDate: "",
    jobtime: "",
    serviceBudget: "",
  });

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const formatTime = (timeString) => {
    const [hour, minute, second] = timeString.split(":");
    let hours = parseInt(hour, 10);
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format and handle 0 as 12
    return `${hours}:${minute} ${ampm}`;
  };

  useEffect(() => {
    if (JobDetail && JobDetail.length > 0) {
      setCategoryDetails({
        catImage: JobDetail[0]?.category?.category_image || "",
        catName: JobDetail[0]?.category?.category_name || "",
        subCatImage: JobDetail[0]?.sub_category?.sub_category_image || "",
        subCatName: JobDetail[0]?.sub_category?.sub_category_name || "",
        venderName: vendorDetail?.name || "",
        serviceDetails: JobDetail[0].job_detail || "",
        jobDate: JobDetail[0].job_dt ? formatDate(JobDetail[0].job_dt) : "",
        jobtime: JobDetail[0].job_tm ? formatTime(JobDetail[0].job_tm) : "",
        serviceBudget: JobDetail[0].job_budget || "",
      });
    }
  }, [JobDetail]);

  const handelAssignJob = () => {
    navigate("/Job-Summary", { state: { vendorDetail, JobDetail } });
  };
  const handleBack = () => {
    const currentLocation = location.pathname;
    const previousPath = document.referrer;
    navigate(-1);
  };

  return (
    <>
      <StyledBox>
        <Box>
          <Typography variant="h5" sx={{ color: "#FFFFFF", fontWeight: 600 }}>
            Service
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={handleBack} className="me-5">
            <ArrowBackIcon sx={{ color: "#FFFFFF" }} />
          </IconButton>
        </Box>
      </StyledBox>
      <StyledOrderBox2 className="mb-3">
        <div className="container">
          <div className="d-flex">
            <img src={categoryDetails.catImage} alt="Suluhu Logo" height="60" />
            <h2 className="ms-3">{categoryDetails.catName}</h2>
          </div>
          <div className="d-flex offset-1">
            <img
              src={categoryDetails.subCatImage}
              alt="Suluhu Logo"
              height="30"
            />
            <h6 className="ms-2 align-self-center" style={{ color: "#0079EA" }}>
              {categoryDetails.subCatName}
            </h6>
          </div>
          <hr />
          <div className="container-fluid">
            <div className="d-flex   mb-2">
              <div className="col-auto">
                <CgNotes
                  style={{
                    height: "50px",
                    width: "50px",
                    margin: "0px 10px 10px 0px ",
                    color: "rgb(68 68 68 / 64%)",
                  }}
                />
              </div>
              <div className="col-auto">
                <div style={{ fontSize: "20px", fontWeight: "600" }}>
                  Vender name
                </div>
                <p style={{ color: "#868686" }}>{categoryDetails.venderName}</p>
              </div>
            </div>

            <div className="d-flex   mb-2">
              <div className="col-auto">
                <RiMapPinFill
                  style={{
                    height: "50px",
                    width: "50px",
                    margin: "0px 10px 10px 0px",
                    color: "rgb(68 68 68 / 64%)",
                  }}
                />
              </div>
              <div>
                <div style={{ fontSize: "20px", fontWeight: "600" }}>
                  Service
                </div>
                <p style={{ color: "#868686" }}>
                  {categoryDetails.serviceDetails}
                </p>
              </div>
            </div>

            <div className="row">
              <div className=" d-flex  col-md-4   ">
                <div className="col-2 me-1">
                  <MdDateRange
                    style={{
                      height: "50px",
                      width: "50px",
                      color: "rgb(68 68 68 / 64%)",
                    }}
                  />
                </div>

                <div className="col-9">
                  <div style={{ fontSize: "20px", fontWeight: "600" }}>
                    Job Date
                  </div>
                  <p style={{ color: "#868686" }}>{categoryDetails.jobDate}</p>
                </div>
              </div>
              <div className=" d-flex  col-md-4   ">
                <div className="col-2 me-2">
                  <IoTimeOutline
                    style={{
                      height: "50px",
                      width: "50px",
                      color: "rgb(68 68 68 / 64%)",
                    }}
                  />
                </div>

                <div className="col-9">
                  <div style={{ fontSize: "20px", fontWeight: "600" }}>
                    Job Time
                  </div>
                  <p style={{ color: "#868686" }}>{categoryDetails.jobtime}</p>
                </div>
              </div>
              <div className=" d-flex  col-md-4   ">
                <div className="col-2 me-2">
                  <img src={vectorImage} alt="Suluhu Logo" />
                </div>

                <div className="col-9">
                  <div style={{ fontSize: "20px", fontWeight: "600" }}>
                    Service Budget
                  </div>
                  <p
                    style={{ color: "#868686" }}
                  >{`KES ${categoryDetails.serviceBudget}`}</p>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center mt-3 ">
              <button className={"buttonForSubmit"} onClick={handelAssignJob}>
                <span>Assign Job</span>
              </button>
            </div>
          </div>
        </div>
      </StyledOrderBox2>
    </>
  );
};

export default AssignPage;
