import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  DialogContentText,
} from "@mui/material";
import AnimatedDialog from "../Auth/AnimatedDialog";

const LogoutConfirmationDialog = ({ open, onClose, onConfirm }) => {
  return (
    <AnimatedDialog open={open} onClose={onClose}>
      <DialogTitle>Logout!</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to log out?</DialogContentText>
      </DialogContent>
      <DialogActions sx={{justifyContent:"center"}}>
        <Button variant="outlined" onClick={onConfirm} color="primary">
          Yes
        </Button>
        <Button variant="outlined" onClick={onClose} color="error">
          No
        </Button>
      </DialogActions>
    </AnimatedDialog>
  );
};

export default LogoutConfirmationDialog;
