import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";
import { endpoints } from "../../config/config";

const initialState = {
  vendors: [],
  loading: false,
  error: null,
};

export const fetchNearbyVendors = createAsyncThunk(
  "nearbyVendors/fetchNearbyVendors",
  async (nearVendor, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        endpoints.nearby_vendors,
        nearVendor
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const nearbyVendorsSlice = createSlice({
  name: "nearbyVendors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNearbyVendors.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNearbyVendors.fulfilled, (state, action) => {
        state.loading = false;
        state.vendors = action.payload;
        state.error = null;
      })
      .addCase(fetchNearbyVendors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Error fetching nearby vendors";
      });
  },
});

export default nearbyVendorsSlice.reducer;
