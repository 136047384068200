import React, { useEffect, useState, useRef } from "react";
import {
  GoogleMap,
  Marker as AdvancedMarkerElement,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useUserAuth } from "../../context/UserAuthContext";

const MapHelper = ({
  setFinalCoordinates,
  finalCoordinates,
  setShowMap,
  setAddress,
  setFirstPart,
  setDateModal,
  setSelectedAddress
}) => {
  const { API_KEY, libraries } = useUserAuth();
  const [currentLocation, setCurrentLocation] = useState(null);
  const [map, setMap] = useState(null);
  const [places, setPlaces] = useState([]);
  const [coordinates, setCoordinates] = useState(null);
  const [infoWindowContent, setInfoWindowContent] = useState("");
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);
  const [infoWindowVisible, setInfoWindowVisible] = useState(false);
  const autocompleteServiceRef = useRef(null);
  const autocompleteRef = useRef(null);
  const containerRef = useRef(null);
  const [selected1 ,setSelected1]= useState(null);
  const [isCordinates ,setIsCordinates]= useState();

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setPlaces([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (currentLocation && map) {
      map.panTo(currentLocation);
    }
  }, [currentLocation, map]);

  const containerStyle = {
    height: "50vh",
    width: "100%",
  };

  const defaultCenter = coordinates;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
    libraries: libraries,
  });

  const geocodeCoordinates = (lat, lng) => {
    if (!window.google || !window.google.maps) {
      console.error("Google Maps API not initialized");
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat, lng };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          const address = results[0].formatted_address;
          setAddress(address);
          setSelected1(address)
          setIsCordinates({
            lat,
            lng,
            address: address,
          });
          setInfoWindowContent(address);
          setInfoWindowPosition({ lat, lng });
          setInfoWindowVisible(true);
        } else {
          console.log("No results found");
        }
      } else {
        console.log("Geocoder failed due to: " + status);
      }
    });
  };

  useEffect(() => {
    const storedCoordinates = sessionStorage.getItem("userLocation");

    if (storedCoordinates) {
      try {
        let a = JSON.parse(storedCoordinates);
        if (a.lat && a.lng) {
          setCoordinates(a);
          geocodeCoordinates(a.lat, a.lng);
        } else {
          console.error("Invalid coordinates in sessionStorage");
        }
      } catch (error) {
        console.error("Error parsing sessionStorage data", error);
      }
    } else {
      console.log("No stored coordinates found");
    }
  }, []);

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setCoordinates({ lat, lng });
    geocodeCoordinates(lat, lng);
  };

  const handleMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setCoordinates({ lat, lng });
    geocodeCoordinates(lat, lng);
  };

  const handleProceed = () => { 
    geocodeCoordinates(coordinates.lat, coordinates.lng);
    setFirstPart(false);
    setShowMap(false);
    setDateModal(false);
    setSelectedAddress(selected1)
    setFinalCoordinates(isCordinates);
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <>

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={currentLocation || defaultCenter}
        zoom={13}
        onClick={handleMapClick}
        onLoad={(mapInstance) => {
          setMap(mapInstance);
          autocompleteServiceRef.current =
            new window.google.maps.places.AutocompleteService();
        }}
      >
        {coordinates && (
          <>
            <AdvancedMarkerElement
              position={coordinates}
              draggable={true}
              onDragEnd={handleMarkerDragEnd}
            />
            {infoWindowVisible && (
              <InfoWindow
                position={infoWindowPosition}
                onCloseClick={() => setInfoWindowVisible(false)}
                options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
              >
                <div>{infoWindowContent}</div>
              </InfoWindow>
            )}
          </>
        )}
      </GoogleMap>
      <hr />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          onClick={handleProceed}
          disabled={!coordinates}
          style={{
            padding: "10px 50px",
            borderRadius: "25px",
            backgroundColor: !coordinates ? "#B0C4DE" : "#007BFF",
            color: "white",
            border: "none",
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          Select
        </button>
      </div>
    </>
  );
};

export default MapHelper;
