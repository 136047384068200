import React, { useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MyOrderIcon from "../../assets/images/MyOrder.png";
import FavouriteVendorsIcon from "../../assets/images/Vendors.png";
import NotificationsIcon from "../../assets/images/Notifications.png";
import LogoutIcon from "../../assets/images/logout.png";
import { useDispatch } from "react-redux";
import { logout } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";
import LogoutConfirmationDialog from "../comman/LogoutConfirmationDialog";
import Notifications from "./Notifications";

const pages = [
  { text: "My Order", icon: MyOrderIcon, path: "/my-order" },
  {
    text: "Favourite Vendors",
    icon: FavouriteVendorsIcon,
    path: "/vendors-list",
  },
  { text: "Notifications", icon: NotificationsIcon, path: "Notifications" },
  { text: "Logout", icon: LogoutIcon, path: "logout" },
];

const NavMenu = () => {
  const { userData, getNotification } = useUserAuth();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenuItemClick = (page) => {
    if (page.path === "logout") {
      setOpenDialog(true);
    } else if (page.path === "Notifications") {
      setAnchorEl(anchorElNav);
      getNotification();
    } else {
      navigate(page.path);
    }
    handleCloseNavMenu();
  };

  const handleLogoutConfirm = () => {
    dispatch(logout());
    navigate("/login");
    setOpenDialog(false);
    handleCloseNavMenu();
  };
  return (
    <>
      <Box>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
        >
          {pages.map((page) => (
            <MenuItem key={page.text} onClick={() => handleMenuItemClick(page)}>
              <ListItemIcon>
                <img
                  src={page.icon}
                  alt={`${page.text} icon`}
                  style={{ width: 24, height: 24 }}
                />
              </ListItemIcon>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  textAlign: "left",
                }}
              >
                {page.text}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <LogoutConfirmationDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleLogoutConfirm}
      />
      <Notifications anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
};

export default NavMenu;
