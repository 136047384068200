import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '50px',
  color: theme.palette.primary.main,
  padding: '5px 20px',
  textTransform: 'none',
  fontSize: '16px',
  '&:hover': {
    backgroundColor: 'rgba(0, 121, 234, 0.1)',
  },
}));

const CustomButton1 = () => {
  return (
    <StyledButton>
      Yes
    </StyledButton>
  );
};

export default CustomButton1;
