import { setAuthToken } from "../api/axiosInstance";

export const setToken = (token) => {
  localStorage.setItem("token", token);
  setAuthToken(token);
};

export const getToken = () => {
  return localStorage.getItem("token");
};
export const setUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const setUserLocation = (location) => {
  sessionStorage.setItem("userLocation", JSON.stringify(location));
};
export const getUserLocation = () => {
 const location = sessionStorage.getItem("userLocation");
 return location ? JSON.parse(location) : null
};

export const getUser = () => {
  const userData = localStorage.getItem("user");
  return userData ? JSON.parse(userData) : null;
};
export const getMobile = () => {
  const mobile = localStorage.getItem("mobile");
  return mobile ? JSON.parse(mobile) : null;
};

export const clearAuth = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  sessionStorage.removeItem("userLocation");
  setAuthToken(null);
};
