import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import loginIcon from "../../assets/images/logohome.png";
import HomePng from "../../assets/images/suluhu-home.png";
import UserMenu from "./UserMenu";
import NavMenu from "./NavMenu";

function ResponsiveAppBar({ children }) {
  
  return (
    <AppBar position="fixed" color="default" elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
          >
            <img src={HomePng} alt="Suluhu Logo" style={{ height: 55 }} />
            <img
              src={loginIcon}
              alt="Suluhu Logo"
              style={{ height: 30, marginRight: 10 }}
            />
          </Box>
          <Box sx={{ flexGrow: 1 }}>{children}</Box>
          <UserMenu />
          <NavMenu />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
