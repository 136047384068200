import axios from "axios";
import { BASE_URL } from "../config/config";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
export const axiosFormDataInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const setAuthToken = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axiosFormDataInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    localStorage.setItem("token", token);
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"];
    delete axiosFormDataInstance.defaults.headers.common["Authorization"];
    localStorage.removeItem("token");
  }
};
const token = localStorage.getItem("token");
if (token) {
  setAuthToken(token);
}

export default axiosInstance;
