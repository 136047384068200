import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Dialog, DialogContent, Button, Box, Typography } from "@mui/material";
import successModalImage from "../../assets/images/successModalImage.png";
import { useTheme } from "@mui/material/styles";
import "./CostomeModalCss.css";
const SuccessModal = ({ open, handleClose, jobDate, formattedTime }) => {
 


  const formatDate = (dateString) => {
    const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };


 

  return (
    open && (
      <div className="dialog">
        <div className="dialog-content">
          <div className="modal-top">
            <div className="modal-icon">
              <img src={successModalImage} alt="Success Icon" />
            </div>
          </div>
          <div className="modal-bottom mt-4">
            <h3 style={{ color: "white", margine: "20px" }}>
              Your broadcast job is placed!
            </h3>
          </div>
          <div
            style={{
              position: "absolute",
              top: "120px",
              left: "0",
              width: "100%",
              minHeight: "70px",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
              backgroundColor: "white",
            }}
          >
            <p className="pt-3" style={{ fontWeight: "600" }}>
              Schedule on <span style={{ color: "#0079EA" }}>{formatDate(jobDate)}</span> at{" "}
              {formattedTime}
            </p>

            <div
              className=" m-4 p-1
           d-flex align-items-center justify-content-center "
            >
              <button
                className={"button1"}
                style={{ minWidth: "50%" }}
                onClick={handleClose}
              >
                <span>View Broadcast</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default SuccessModal;
