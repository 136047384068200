import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ChatArea,
  ChatHeader,
  ChatInput,
  ChatMessages,
  MessageBox,
  MessageLeft,
  MessageRight,
  Root,
  Sidebar,
  SkillButton,
} from "./ChatStyle";
import SendIcon from "@mui/icons-material/Send";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import StarIcon from "@mui/icons-material/Star";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../utils/authUtils";
import { BackButton } from "../UI/Button";
import { formatChatTime } from "../../utils/dateUtils";
import { format } from "date-fns";
import withRouter from "../comman/withRouter";
import { fetchOrderDetails } from "../../features/Order/ordersSlice";
import Loader from "../comman/Loader";
import { fetchVendorDetails } from "../../features/Vendors/vendorDetailsSlice";
import JobAssign from "./JobAssign";
const Chat = ({ router }) => {
  const { params, location } = router;

  const { order_id, vendor_id } = params ?? {};
  const dispatch = useDispatch();

  const { loading, vendorDetail } = useSelector((state) => state.vendorDetails);
  const { name, username, ratings, profile_img, nearKm, categories_list } =
    vendorDetail ?? {};
  const theme = useTheme();
  const { user_id } = getUser();
  const SULUHU = "SULUHU";
  const [chats, setChats] = useState([]);

  const [currentChatId, setCurrentChatId] = useState(null);
  const [text, setText] = useState("");
  //   const [loading, setLoading] = useState(true);
  const messagesEndRef = useRef(null);
  const InputRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    dispatch(fetchVendorDetails({ user_id: user_id, vendor_id: vendor_id }));
  }, [dispatch, vendor_id, user_id]);

  const fetchChats = async (customerId, providerId) => {
    const q = query(
      collection(db, SULUHU),
      where("customer_id", "==", customerId?.toString()),
      where("provider_id", "==", providerId)
    );
    const querySnapshot = await getDocs(q);
    const chatList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setChats(chatList);
    scrollToBottom();
  };

  const findExistingChat = async ({ customerId, providerId }) => {
    const q = query(
      collection(db, SULUHU),
      where("customer_id", "==", customerId?.toString()),
      where("provider_id", "==", providerId)
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const existingChat = querySnapshot.docs[0];
      return existingChat.id;
    }
    return null;
  };
  const createChat = async ({ customerId, providerId }) => {
    const existingChatId = await findExistingChat({
      customerId,
      providerId,
    });
    if (existingChatId) {
      return existingChatId;
    }
    const documentsData = {
      customer_id: customerId,
      provider_id: providerId,
      messagedata: [],
    };
    const docRef = await addDoc(collection(db, SULUHU), documentsData);
    return docRef.id;
  };

  useEffect(() => {
    if (user_id && vendor_id) {
      createChat({
        customerId: user_id.toString(),
        providerId: vendor_id,
      }).then((newId) => {
        setCurrentChatId(newId);
        fetchChats(user_id, vendor_id);
      });
    }
  }, [user_id, vendor_id]);

  useEffect(() => {
    const q = query(
      collection(db, SULUHU),
      where("customer_id", "==", user_id?.toString()),
      where("provider_id", "==", vendor_id)
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const chatList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setChats(chatList);
      scrollToBottom();
    });

    return () => unsubscribe();
  }, [user_id, vendor_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (text.trim() === "") {
      return;
    }
    const newMessage = {
      message: text,
      msg_date_time: new Date().toISOString(),
      sender_id: user_id?.toString(),
      sender_type: "Customer",
    };
    setChats((prevChats) => {
      const updatedChats = prevChats.map((chat) =>
        chat.id === currentChatId
          ? {
              ...chat,
              messagedata: [...chat.messagedata, newMessage],
            }
          : chat
      );
      scrollToBottom();
      return updatedChats;
    });
    const chatRef = doc(db, SULUHU, currentChatId);
    await updateDoc(chatRef, {
      messagedata: arrayUnion(newMessage),
    });
    setText("");
  };

  const getMessageDateDivider = (date) => {
    const now = new Date();
    const messageDate = new Date(date);
    now.setHours(0, 0, 0, 0);
    messageDate.setHours(0, 0, 0, 0);

    const diffTime = now - messageDate;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays === 0) {
      return "Today";
    } else if (diffDays === 1) {
      return "Yesterday";
    } else {
      return format(messageDate, "dd MMMM yyyy");
    }
  };
  let lastMessageDate = null;
  const jobStatus = location?.state?.jobDetail[0]?.job_status || null;


  if (loading) return <Loader />;
  return (
    <>
      <ChatHeader>
        <Typography variant="h6"></Typography>
        <Box>
          <BackButton />
        </Box>
      </ChatHeader>
      <Root>
        <Sidebar>
          <Avatar
            alt="Michel Watson"
            src={profile_img}
            sx={{ width: 100, height: 100, mb: 2 }}
          />
          <Typography variant="h6" align="center">
            {name}
          </Typography>
          <Box display="flex" alignItems="center" mt={1}>
            <Box sx={{ mx: 2 }}>
              <StarIcon style={{ color: "#FFD700" }} />
              <Typography variant="caption" color="text.secondary">
                {ratings}
              </Typography>
            </Box>
            <Box>
              <AddRoadIcon color="primary" />
              <Typography
                variant="caption"
                color="text.secondary"
                textAlign={"left"}
              >
                {nearKm} Km
              </Typography>
            </Box>
          </Box>
          <Box mt={2} textAlign="center">
            {categories_list?.map((skill) => (
              <SkillButton key={skill.cat_id} variant="outlined" size="small">
                <Avatar
                  src={skill.cat_img}
                  alt={skill.cat_name}
                  sx={{ mr: 1, width: 24, height: 24 }}
                />
                {skill.cat_name}
              </SkillButton>
            ))}
          </Box>
          <Typography variant="caption" mt={2} align="left"></Typography>
        </Sidebar>
        <ChatArea>
          {location?.state?.mode === "broadcast" &&
            jobStatus !== "CONFIRMED" && (
              <JobAssign
                vendorDetail={vendorDetail}
                JobDetail={location?.state?.jobDetail}
              />
            )}
          <ChatMessages>
            {chats?.map((chat) =>
              chat.messagedata?.map((message, index) => {
                const messageDate = new Date(message.msg_date_time);
                const currentMessageDateDivider = getMessageDateDivider(
                  message.msg_date_time
                );
                const shouldShowDivider =
                  !lastMessageDate ||
                  getMessageDateDivider(lastMessageDate) !==
                    getMessageDateDivider(messageDate);
                lastMessageDate = messageDate;
                return (
                  <React.Fragment key={index}>
                    {shouldShowDivider && (
                      <Typography
                        variant="caption"
                        align="center"
                        display="block"
                        sx={{
                          margin: "10px 0",
                          color: theme.palette.text.secondary,
                        }}
                      >
                        {getMessageDateDivider(message.msg_date_time)}
                      </Typography>
                    )}

                    {message.sender_id === user_id?.toString() ? (
                      <MessageRight>
                        <MessageBox align="right">
                          {message.message}
                          <Typography variant="caption" display="block">
                            {formatChatTime(message.msg_date_time)}
                          </Typography>
                        </MessageBox>
                      </MessageRight>
                    ) : (
                      <MessageLeft>
                        <MessageBox align="left">
                          {message.message}
                          <Typography variant="caption" display="block">
                            {formatChatTime(message.msg_date_time)}
                          </Typography>
                        </MessageBox>
                      </MessageLeft>
                    )}
                  </React.Fragment>
                );
              })
            )}
            <div ref={messagesEndRef} />
          </ChatMessages>
          <form onSubmit={handleSubmit}>
            <ChatInput>
              <TextField
                autoFocus
                fullWidth
                placeholder="Say, Hello"
                size="small"
                sx={{ backgroundColor: "#fff" }}
                variant="outlined"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
              <IconButton color="primary" sx={{ ml: 1 }} type="submit">
                <SendIcon />
              </IconButton>
            </ChatInput>
          </form>
        </ChatArea>
      </Root>{" "}
    </>
  );
};

export default withRouter(Chat);
