import React, { useEffect } from "react";
import MyOrderList from "./MyOrderList";
import { useNavigate } from "react-router-dom";

const MyOrder = () => {
 
 

  return <MyOrderList />;
};

export default MyOrder;
