import React from 'react';
import { Avatar, Typography, Box, ButtonBase } from '@mui/material';
import { styled } from '@mui/system';

const CategoryButtonContainer = styled(ButtonBase)(({ theme, selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  minWidth: 100,
  minHeight: 100,
  border: '1px solid #e0e0e0',
  borderRadius: 8,
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
  ...(selected && {
    borderColor: theme.palette.primary.main,
  }),
}));

const CategoryButtonAvatar = styled(Avatar)({
  width: 40,
  height: 40,
  marginBottom: 8,
});

const CategoryButton = ({ icon, title, selected, onClick }) => {
  return (
    <CategoryButtonContainer onClick={onClick} selected={selected}>
      <CategoryButtonAvatar src={icon} alt={title} />
      <Typography variant="body2">{title}</Typography>
    </CategoryButtonContainer>
  );
};

export default CategoryButton;
