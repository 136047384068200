import React from "react";
import { TextField, InputAdornment } from "@mui/material";

const CustomTextField = ({
  id,
  label,
  name,
  autoComplete,
  autoFocus,
  endAdornmentIcon: EndAdornmentIcon,
  formik,
  type = "text",
  loginPage = false,
  ...props
}) => {
  //   const handleChange = (event) => {
  //     const value = event.target.value;
  //     if (value.length === 1 && value === " ") {
  //       return;
  //     }
  //     formik.setFieldValue(name, value);
  //   };
  const handleChange = (e) => {
    const inputValue = e.target.value;
    const otherFieldName = name === "email" ? "mobile" : "email";
    const hasLeadingSpace = /^\s/.test(inputValue);
    const isValidNumber =
      type === "number" ? /^[0-9]*$/.test(inputValue) : true;

    if (!hasLeadingSpace && isValidNumber) {
      if (loginPage && inputValue.length > 0) {
        formik.setFieldValue(otherFieldName, "");
      }
      formik.handleChange(e);
    }
  };
  return (
    <TextField
      margin="normal"
      // size="small"
      fullWidth
      id={id}
      label={label}
      name={name}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {EndAdornmentIcon && <EndAdornmentIcon />}
          </InputAdornment>
        ),
      }}
      value={formik.values[name]}
      onChange={handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={
        formik.touched[name] && formik.errors[name] ? formik.errors[name] : ""
      }
      {...props}
    />
  );
};

export default CustomTextField;
