import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const JobAssign = ({vendorDetail ,JobDetail}) => {
  const navigate = useNavigate();
  const NavigateTo = () => {
    navigate("/assign-job", { state: { vendorDetail , JobDetail } });
  };





  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        backgroundColor: "black",
        color: "white",
        padding: "5px 10px",
        borderRadius: "1.5rem",
        margin: ".2rem auto",
        maxWidth: "35rem",
        width: "100%",
      }}
    >
      <Typography variant="subtitle1">
        Do you want to assign the job?
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ borderRadius: "2rem" }}
        onClick={NavigateTo}
      >
        Assign
      </Button>
    </Box>
  );
};

export default JobAssign;
