import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  IconButton,
  Card,
  CardContent,
  styled,
  Avatar,
  Divider,
} from "@mui/material";
import { CalendarToday, AccessTime } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrdersList } from "../../features/Order/ordersSlice";
import { getUser } from "../../utils/authUtils";
import Loader from "../../components/comman/Loader";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useNavigate } from "react-router-dom";


const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "16px",
  backgroundColor: "#1976d2",
  background: "linear-gradient(90deg, #0079EA 0%, #004484 100%)",
  color: "#fff",
  padding: "16px",
  height: "180px",
});
const StyledOrderBox = styled(Box)({
  padding: "20px",
  background: "#ffffff",
  width: "90%",
  margin: "auto",
  zIndex: 10,
  marginTop: "-100px",
  borderRadius: "1rem",
});


const ViewS = () => {


  return (
    <>
      <StyledBox>
        <Box>
          <Typography variant="h5" sx={{ color: "#FFFFFF", fontWeight: 600 }}>
            Service
          </Typography>
        </Box>
        <Box>
          <IconButton >
            <SearchIcon sx={{ color: "#FFFFFF" }} />
          </IconButton>
          <IconButton >
            <ArrowBackIcon sx={{ color: "#FFFFFF" }} />
          </IconButton>
        </Box>
      </StyledBox>
      <StyledOrderBox>
        <Grid container spacing={3}>

          
        </Grid>
      </StyledOrderBox>
    </>
  );
};

export default ViewS;