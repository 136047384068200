import React from "react";
import { getToken } from "../utils/authUtils";
import { Navigate } from "react-router-dom";

const Authmiddleware = ({ children }) => {
  const token = getToken();
  return token ? <>{children}</> : <Navigate to="/login" />;
};

export default Authmiddleware;
