import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";
import { endpoints } from "../../config/config";

export const fetchVendorDetails = createAsyncThunk(
  "vendorDetails/fetchVendorDetails",
  async (vendorData, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        endpoints.vendor_details,
        vendorData
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const vendorDetailsSlice = createSlice({
  name: "vendorDetails",
  initialState: {
    vendorDetail: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVendorDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVendorDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.vendorDetail = action.payload;
      })
      .addCase(fetchVendorDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export default vendorDetailsSlice.reducer;
