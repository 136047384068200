import React, { useEffect, useRef, useState } from "react";
import { Box, TextField } from "@mui/material";

const OtpInput = ({ length, onChange, shouldFocusFirst, otp, setOtp }) => {
  const inputRefs = useRef([]);

  useEffect(() => {
    if (shouldFocusFirst && inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [shouldFocusFirst]);

  const handleChange = (value, index) => {
    if (!/^\d*$/.test(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    onChange(newOtp.join(""));
    if (value && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (otp[index] === "") {
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      } else {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        onChange(newOtp.join(""));
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      inputRefs.current[index - 1].focus();
    } else if (e.key === "ArrowRight" && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };
  const handlePaste = (e) => {
    e.preventDefault();
    let pasteData = e.clipboardData.getData("text").slice(0, length);
    if (!/^\d*$/.test(pasteData)) return;
    const newOtp = [...otp];
    const pasteArray = pasteData.split("");
    // for (let i = 0; i < length; i++) {
    //   if (!newOtp[i]) newOtp[i] = "";
    // }
    pasteArray.forEach((char, i) => {
      newOtp[i] = char;
    });
    setOtp(newOtp);
    onChange(newOtp.join(""));
    const nextIndex =
      pasteArray.length < length ? pasteArray.length : length - 1;
    inputRefs.current[nextIndex].focus();
    // const filledIndex = newOtp.findIndex((val) => val === "");
    // if (filledIndex === -1) {
    //   inputRefs.current[length - 1].focus();
    // } else {
    //   inputRefs.current[filledIndex].focus();
    // }
  };
  return (
    <Box display={"flex"} justifyContent={"center"} gap={2}>
      {otp.map((digit, index) => (
        <TextField
          key={index}
          value={digit}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          inputProps={{
            maxLength: 1,
            style: { textAlign: "center" },
            ref: (el) => (inputRefs.current[index] = el),
          }}
          onPaste={handlePaste}
          sx={{ width: "3rem" }}
        />
      ))}
    </Box>
  );
};
export default OtpInput;
