import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastOptions = {
  position: toast?.POSITION?.TOP_RIGHT,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const notifySuccess = (message) => {
  toast.success(message, toastOptions);
};
const notifyError = (message) => {
  toast.error(message, toastOptions);
};
const notifyInfo = (message) => {
  toast.info(message, toastOptions);
};

const notifyWarning = (message) => {
  toast.warn(message, toastOptions);
};

export { notifySuccess, notifyError, notifyInfo, notifyWarning };
